<template>
  <div>
    <v-app>
      <v-container fill-height fluid height="90vh">
        <v-row align="center" justify="center">
          <v-spacer />
          <v-col cols="8">
            <v-card class="px-12 py-4">
              <v-card-title class="pl-7"><b>Zeitplan einrichten</b></v-card-title>
              <v-card-text>
                <v-container>
                  <v-form v-model="valid">
                    <v-row class="pl-0">
                      <v-col cols="auto">
                        <v-checkbox @change="overlap = 0" v-model="weekdays" label="Mo" :value="0"></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox @change="overlap = 0" v-model="weekdays" label="Di" :value="1"></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox @change="overlap = 0" v-model="weekdays" label="Mi" :value="2"></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox @change="overlap = 0" v-model="weekdays" label="Do" :value="3"></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox @change="overlap = 0" v-model="weekdays" label="Fr" :value="4"></v-checkbox
                      ></v-col>
                      <v-col cols="auto">
                        <v-checkbox @change="overlap = 0" v-model="weekdays" label="Sa" :value="5"></v-checkbox
                      ></v-col>
                      <v-col cols="auto">
                        <v-checkbox @change="overlap = 0" v-model="weekdays" label="So" :value="6"></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5"
                        ><v-menu
                          ref="startDatePicker"
                          v-model="showStartDatePicker"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          min-width="auto"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="getReadableDate(startDate)"
                              label="Datum Beginn"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              v-bind="attrs"
                              :rules="requiredRule.concat(overlappingSchedules)"
                            ></v-text-field
                          ></template>
                          <v-date-picker
                            v-model="startDate"
                            v-if="showStartDatePicker"
                            no-title
                            :min="todaysDate"
                            locale="de-DE"
                            first-day-of-week="1"
                            color="primary"
                            show-current
                            @click:date="$refs.startDatePicker.save(date); overlap = 0"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-spacer />
                      <v-col cols="5"
                        ><v-menu
                          ref="endDatePicker"
                          v-model="showEndDatePicker"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          min-width="auto"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="getReadableDate(endDate)"
                              label="Datum Ende"
                              append-icon="mdi-calendar"
                              :disabled="!startDate"
                              readonly
                              v-on="on"
                              v-bind="attrs"
                              :rules="requiredRule.concat(overlappingSchedules)"
                            ></v-text-field
                          ></template>
                          <v-date-picker
                            v-model="endDate"
                            v-if="showEndDatePicker"
                            :min="startDate"
                            no-title
                            locale="de-DE"
                            first-day-of-week="1"
                            show-current
                            @click:date="$refs.endDatePicker.save(date); overlap = 0"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">
                        <v-menu
                          v-model="showStartTimePicker"
                          :close-on-content-click="false"
                          ref="startTimePicker"
                          transition="scale-transition"
                          :return-value.sync="time"
                          max-width="290px"
                          min-width="290px"
                          offset-y
                          :nudge-right="32"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              label="Uhrzeit Beginn"
                              readonly
                              :rules="requiredRule.concat(overlappingSchedules)"
                              :disabled="!endDate"
                              v-model="startTime"
                              append-icon="mdi-clock-time-four-outline"
                            >
                            </v-text-field
                          ></template>
                          <v-time-picker
                            v-if="showStartTimePicker"
                            v-model="startTime"
                            format="24hr"
                            full-width
                            :allowed-minutes="allowedMinutes"
                            :allowed-hours="allowedHours"
                            @click:minute="$refs.startTimePicker.save(time); overlap = 0"
                          >
                          </v-time-picker></v-menu
                      ></v-col>
                      <v-spacer />
                      <v-col cols="5" style="display: flex; justify-content: center"
                        ><v-menu
                          v-model="showEndTimePicker"
                          :close-on-content-click="false"
                          ref="endTimePicker"
                          transition="scale-transition"
                          :return-value.sync="time"
                          max-width="290px"
                          min-width="290px"
                          offset-y
                          :nudge-right="32"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              label="Uhrzeit Ende"
                              v-model="endTime"
                              readonly
                              :rules="[overlappingSchedules]"
                              :disabled="!startTime"
                              append-icon="mdi-clock-time-four-outline"
                            >
                            </v-text-field
                          ></template>
                          <v-time-picker
                            v-if="showEndTimePicker"
                            v-model="endTime"
                            format="24hr"
                            full-width
                            :min="startTime"
                            :allowed-minutes="allowedMinutes"
                            :allowed-hours="allowedHours"
                            @click:minute="$refs.endTimePicker.save(time); overlap = 0"
                          >
                          </v-time-picker></v-menu
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5"
                        ><v-text-field
                          label="Termindauer (in Minuten)"
                          type="number"
                          v-model="duration"
                          :rules="requiredRule.concat(overlappingSchedules)"
                          :disabled="true"
                        ></v-text-field
                      ></v-col>
                      <v-spacer />
                      <v-col cols="5"
                        ><v-select
                          v-model="selectedStages"
                          label="Bühnen"
                          multiple
                          :items="stages"
                          item-text="name"
                          item-value="id"
                          return-object
                          :rules="requiredStageRule.concat(overlappingSchedules)"
                          @change="overlap = 0"
                        ></v-select
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <v-btn :disabled="!valid || weekdays.length == 0" class="basicButton" @click="submit">Speichern</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
      <div class="fullScreenOverlay" v-if="loading">
        <div class="overlayBackground" style="opacity: 0.1"></div>
        <v-progress-circular
          indeterminate
          color="primary"
          class="overlayDisplay"
        ></v-progress-circular>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import { getReadableDate } from "../../js/helperFunctions.js";

export default {
  data() {
    return {
      weekdays: [],
      time: undefined,
      date: undefined,
      startTime: undefined,
      endTime: undefined,
      startDate: undefined, // yyyy-mm-dd format!
      endDate: undefined,
      selectedStages: [],
      duration: 30,

      showStartTimePicker: undefined,
      showEndTimePicker: undefined,
      showStartDatePicker: undefined,
      showEndDatePicker: undefined,

      overlap: 0,

      loading: undefined,

      scheduleData: undefined,

      valid: false,

      stages: [],
      requiredRule: [(v) => !!v || "Pflichtfeld!"],
      requiredStageRule: [(v) => v.length >= 1 || "Bitte mind. 1 Bühne auswählen!"],
      requiredWeekdays: [(v) => v.length >= 1 || "Bitte mind. 1 Wochentag auswählen!"],
      allowedMinutes: (v) => v == 0 || v == 30,
      allowedHours: (v) => v >= 8 && v <= 17,
    };
  },
  computed: {
    todaysDate() {
      let today = new Date();
      return `${today.getFullYear()}-${
        today.getMonth() + 1 < 10 ? "0" + today.getMonth() + 1 : today.getMonth() + 1
      }-${today.getDate() < 10 ? "0" + today.getDate() : today.getDate()}`;
    },
    overlappingSchedules() {
      return this.overlap == 2 ? 'Es existiert bereit ein Zeitplan in diesem Zeitraum' : true
    },
    selectedDays() {
      return this.weekdays.length
    }
  },
  methods: {
    fetchStages() {
      axios
        .get(axios.defaults.baseURL + "stages/")
        .then((resp) => {
          for (let i = 0; i < resp.data.length; i++) {
            this.stages.push({
              name: resp.data[i].name,
              id: resp.data[i].id,
              location: resp.data[i].location,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getReadableDate: getReadableDate,
    submit() {
      this.loading = true;

      let dataObject = {
        start_date: this.startDate,
        end_date: this.endDate,
        start_time: this.startTime,
        end_time: this.endTime,
        duration: this.duration,
        is_monday: this.weekdays.indexOf(0) >= 0 ? true : false,
        is_tuesday: this.weekdays.indexOf(1) >= 0 ? true : false,
        is_wednesday: this.weekdays.indexOf(2) >= 0 ? true : false,
        is_thursday: this.weekdays.indexOf(3) >= 0 ? true : false,
        is_friday: this.weekdays.indexOf(4) >= 0 ? true : false,
        is_saturday: this.weekdays.indexOf(5) >= 0 ? true : false,
        is_sunday: this.weekdays.indexOf(6) >= 0 ? true : false,
      };
      let stages = [];

      for (let i = 0; i < this.selectedStages.length; i++) {
        stages.push(this.selectedStages[i].id);
      }

      dataObject.Stage = stages;

      if (this.scheduleData) dataObject.id = this.scheduleData.id;

      axios
        .post(axios.defaults.baseURL + "appointments/schedules/validate/", dataObject)
        .then((resp) => {
          this.overlap = 1;
          console.log(resp.data);
          if (this.scheduleData) {
            this.patchSchedule(dataObject);
          } else {
            this.postSchedule(dataObject);
          }
        })
        .catch((e) => {
          this.overlap = 2;
          this.loading = false;
          console.log(e);
        });
    },
    postSchedule(dataObject) {
      axios
        .post(axios.defaults.baseURL + "appointments/schedules/post/", dataObject)
        .then(() => {
          this.loading = false;
          this.$router.replace("/zeitplan");
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    patchSchedule(dataObject) {
      dataObject.id = this.scheduleData.id;

      axios
        .patch(
          axios.defaults.baseURL + "appointments/schedules/patch/" + this.scheduleData.id,
          dataObject
        )
        .then(() => {
          this.loading = false;
          this.$router.replace("/zeitplan");
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    setValues(schedule) {
      let split_start_time = schedule.start_time.split(":");
      let split_end_time = schedule.end_time.split(":");

      this.startDate = schedule.start_date;
      this.endDate = schedule.end_date;
      this.startTime = `${split_start_time[0]}:${split_start_time[1]}`;
      this.endTime = `${split_end_time[0]}:${split_end_time[1]}`;
      this.selectedStages = schedule.Stage;
      if (schedule.is_monday) this.weekdays.push(0);
      if (schedule.is_tuesday) this.weekdays.push(1);
      if (schedule.is_wednesday) this.weekdays.push(2);
      if (schedule.is_thursday) this.weekdays.push(3);
      if (schedule.is_friday) this.weekdays.push(4);
      if (schedule.is_saturday) this.weekdays.push(5);
      if (schedule.is_sunday) this.weekdays.push(6);
    },
    fetchSchedule() {
      if (this.$route.params.id != "new") {
        axios
          .get(axios.defaults.baseURL + "appointments/schedules/" + this.$route.params.id)
          .then((resp) => {
            this.scheduleData = resp.data;
            this.setValues(resp.data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    overlapRule(){
      if(this.overlap === 2){
        return "Es existiert bereits ein Zeitplan in diesem Zeitraum"
      }
      return true
    }
  },
  mounted() {
    this.fetchStages();
    this.fetchSchedule();
  },
};
</script>
