<template>
  <div>
    <div
      id="element-to-print-storage"
      ref="element-to-print-storage"
      style="display: none"
    >
      <div class="document" v-if="tire">
        <div style="display: flex; flex-direction: column">
          <img
            src="../../assets/images/ReifenReiboldLogo.png"
            style="margin-left: auto; width: 50%"
            width="30%"
          />
          <h5 style="margin-left: auto">Reifen Reibold - Inh. Tobias Reibold e.K</h5>
          <p style="margin-left: auto; color: black">Mombachstraße 13 - 34127 Kassel</p>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <h2 v-if="tire.in_storage">Einlagerungsbestätigung</h2>
        <h2 v-else>Auslagerungsbestätigung</h2>

        <br />
        <br />

        <table style="width: 100%">
          <thead>
            <tr class="tablePadding">
              <th
                class="tablePadding tableBorder"
                style="text-align: start; background-color: rgba(0, 0, 0, 0.2)"
                colspan="2"
              >
                Kundenname: {{ customerName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="tablePadding tableBorder">
              <td class="tablePadding">
                Fahrzeug: {{ (vehicle.model || "") + " " + (vehicle.type || "") }}
              </td>
              <td class="tablePadding">Dot: {{ tire.dot }}</td>
            </tr>
            <tr class="tablePadding tableBorder">
              <td class="tablePadding">Kennzeichen: {{ vehicle.plate }}</td>
              <td class="tablePadding">Abmessung: {{ tireMessurement }}</td>
            </tr>
            <tr class="tablePadding tableBorder">
              <td class="tablePadding">
                Lagerort: <span v-if="tire.in_storage">{{ tire.tire_storage }}</span
                ><span v-else>--</span>
              </td>
              <td class="tablePadding">
                Felgenart:
                <span v-if="tire.type == 0">Alu </span>
                <span v-if="tire.type == 1">Stahl </span>
                <span v-if="tire.type == 2">Kein </span>
              </td>
            </tr>
            <tr class="tablePadding tableBorder">
              <td class="tablePadding"></td>
              <td class="tablePadding">Wert: {{ (tire.value || "--") + "€" }}</td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <div style="display: flex; justify-content: space-around">
          <div style="width: 30%; text-align: center" class="borderTop">Unterschrift</div>
          <div>
            Datum:
            {{
              new Date().toLocaleDateString({
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "../..//../node_modules/html2pdf.js/dist/html2pdf";

export default {
  name: "assessmentPDF",

  props: ["tire", "vehicle", "customerName", "customerID", "printNow"],

  data() {
    return {};
  },

  computed: {
    tireMessurement() {
      if (
        !this.tire.width ||
        !this.tire.radius ||
        !this.tire.cross_section ||
        !this.tire.weight_index ||
        !this.tire.speed_index
      ) {
        return "--";
      }

      return (
        (this.tire.width || "--") +
        "/" +
        (this.tire.radius || "--") +
        " R" +
        (this.tire.cross_section || "--") +
        " " +
        (this.tire.weight_index || "--") +
        " " +
        (this.tire.speed_index || "--")
      );
    },
  },
  methods: {
    prepareForPrint() {
      let element = document.getElementById("element-to-print-storage");
      element.style = "display:block";

      const opt1 = {
        filename:
          (this.tire.in_storage ? "Einlagerung_" : "Auslagerung_") +
          this.tire.dot +
          ".pdf",
        pagebreak: { before: ".pageBreak" },
        margin: 3,
        autoPrint: false,
        image: { type: "jpeg", quality: 1 },
        jsPDF: { format: "a4", orientation: "portrait" },
        html2canvas: { allowTaint: true, useCORS: true, imageTimeout: 0 },
        preview: true,
      };

      html2pdf()
        .from(element)
        .set(opt1)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          // var totalPages = pdf.internal.getNumberOfPages();

          // for (var i = 1; i <= totalPages; i++) {
          //   pdf.setPage(i);
          //   pdf.setFontSize(10);
          //   pdf.setTextColor(150);
          //   pdf.text(
          //     pdf.internal.pageSize.width / 2 - 2,
          //     pdf.internal.pageSize.height - 10,
          //     "" + i
          //   );

          //   if (i == totalPages) {
          //     pdf.setFontSize(10);
          //     pdf.setTextColor(150);
          //   } else if (i == totalPages - 1) {
          //     pdf.text(
          //       pdf.internal.pageSize.width / 2 + 2,
          //       pdf.internal.pageSize.height - 10,
          //       " - roba.one"
          //     );
          //   }
          // }

          element.style.display = "none";

          pdf.autoPrint();
          //   window.open(pdf.output("bloburl"), "_blank");

          //   //   var blobURL = URL.createObjectURL(blob);
          var blobURL = pdf.output("bloburl");

          var iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = blobURL;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
        });
    },
  },

  watch: {
    printNow() {
      if (this.printNow) {
        this.prepareForPrint();
      }
    },
  },
};
</script>

<style scoped>
.document {
  padding: 2cm;
  padding-bottom: auto;
}

.tablePadding {
  padding: 4px;
}

.tableBorder {
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  border-color: rgb(180, 180, 180);
  border-width: 1px;
}

.borderTop {
  border-top-style: solid;
  border-color: gray;
  border-width: 1px;
}
</style>
