<template>
  <v-expansion-panel
    class="mb-4 mx-2"
    :style="{
      'max-width': isActive || isMobile ? '99%' : 'calc(33% - 12px)',
    }"
    style="border-radius: 37px"
  >
    <v-expansion-panel-header class="pr-9 pl-8" expand-icon="" style="color: #3a5676">
      <div style="display: flex; justify-content: space-between; align-items: center">
        <span>
          <v-icon
            style="background-color: #3a5676; border-radius: 4px"
            color="white"
            v-if="isActive"
            >mdi-minus</v-icon
          >
          <v-icon
            style="background-color: #3a5676; border-radius: 4px"
            color="white"
            v-else
            >mdi-plus</v-icon
          >
          <span class="ml-4"></span>
          <v-btn
            small
            v-if="enableTireStorage"
            :disabled="rowData.space_filled.total_spaces - rowData.space_filled.count <= 0"
            icon
            class="mr-4 storeTireButton"
            style="
              padding-bottom: 2px;
              background-color: #3a5676;
              border-radius: 4px !important;
              width: 24px;
              height: 24px;
              padding-top: 1px;
            "
            @click="storeTire($event)"
          >
            <v-icon :disabled="rowData.space_filled.total_spaces - rowData.space_filled.count <= 0" small color="white">mdi-tray-arrow-down</v-icon>
          </v-btn>
          <b>Reihe {{ rowData.number }}</b>
        </span>
        <span v-if="!isActive">
          <span
            v-if="rowData.space_filled.total_spaces - rowData.space_filled.count <= 0"
          >
            <!-- <b class="mr-2">belegt</b> -->
            <v-icon color="red" large v-if="!isMobile">mdi-cancel</v-icon>
          </span>
          <span v-else>
            <b class="mr-2">
              {{ rowData.space_filled.total_spaces - rowData.space_filled.count }}
              frei
            </b>
            <v-icon color="green" large v-if="!isMobile">mdi-check-circle-outline</v-icon>
          </span>
        </span>
        <span v-else class="pr-1">
          <h3>
            Frei: {{ rowData.space_filled.total_spaces - rowData.space_filled.count }} /
            {{ rowData.space_filled.total_spaces }}
          </h3>
        </span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="px-4">
      <NewStorage :rowData="rowData" :storageName="storage" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import NewStorage from "./NewStorage.vue";

export default {
  props: ["rowData", "activeRow", "storage"],
  data() {
    return {
      enableTireStorage: false,
    };
  },
  components: {
    NewStorage,
  },
  methods: {
    storeTire(event) {
      event.stopPropagation();
      event.preventDefault();

      this.$emit("storeTire", this.rowData.id);
    },
  },
  computed: {
    isMobile() {
      if (this.$vuetify.breakpoint.width < 1500) return true;
      return false;
    },
    isActive() {
      if (this.activeRow + 1 == this.rowData.number) return true;
      return false;
    },
  },
  mounted() {
    if (this.$route.params.reifen) {
      this.enableTireStorage = true;
    }
  },
};
</script>
