<template>
  <div style="display: flex; flex-direction: column-reverse">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span v-on="on" v-bind="attrs" style="display: flex; flex-direction: column-reverse">
          <span v-for="index in rowData.height" :key="index" style="margin-bottom: 2px">
          <v-img
            class="tireImage"
            :width="imageWidth"
            :src="
              placeData.space_filled >= index
                ? require('@/assets/images/reifenstapel.svg')
                : require('@/assets/images/Platzhalter.svg')
            "
          /></span>
        </span>
      </template>
      R{{rowData.number}} P{{ placeData.number }}
    </v-tooltip>
    <v-tooltip top v-if="placeData.space_filled > rowData.height">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-on="on" v-bind="attrs" color="error">mdi-alert</v-icon>
      </template>
      {{ placeData.space_filled }} Reifensätze
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: ["placeData", "rowData"],
  data() {
    return {
      imageWidth: "40px",
    };
  },
};
</script>
