<template>
  <div>
    <v-app-bar fixed app color="toolbar" v-if="bigScreen" height="55px">
      <v-container style="height: 44px" fluid class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" class="pa-0 ma-0 text-center">
            <h1>Terminbuchung</h1>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-app-bar fixed app color="toolbar" style="height: 55px" v-else>
      <v-container style="height: 44px" fluid class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" class="pa-0 ma-0 text-center">
            <h1>Terminbuchung</h1>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    bigScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
        default:
          return false;
      }
    },
    headerCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return [2, 4];
        case "sm":
          return [2, 4];
        case "md":
          return [1, 4];
        case "lg":
          return [3, 3];
        case "xl":
          return [3, 3];
        default:
          return 8;
      }
    },
  },
};
</script>
