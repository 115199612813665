<template>
  <div id="vehicleDetail">
    <v-form ref="vehiclePostForm">
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col :cols="colLimit">
            <v-card
              height="fit-content"
              min-height="50vh"
              class="ma-8 pa-8 mt-16"
              flat
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <v-btn
                class="overlayClose rounded-xl"
                flat
                icon
                @click="closeOverlay(undefined)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-container fluid>
                <v-row>
                  <v-spacer />
                  <v-col cols="4" class="py-0"
                    ><h2>{{ title }}</h2></v-col
                  >
                  <v-spacer />
                </v-row>

                <v-row class="mt-4">
                  <v-col cols="4" class="py-0"><h3>Fahrzeugdaten</h3></v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="py-0">
                    <v-text-field
                      label="Kennzeichen (AB-12 34)"
                      v-model="plate"
                      :rules="plateRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-combobox
                      label="Hersteller"
                      :value="manufacturer"
                      @keydown="writeVehicleManufacturerByEvent"
                      @input="writeVehicleManufacturerByInput"
                      :items="manufacturers"
                      :rules="requiredRules"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-text-field
                      label="Model"
                      v-model="model"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0" style="display: flex; align-items: center">
                    <v-menu
                      class="py-0"
                      ref="inspectionDateMenu"
                      v-model="inspectionDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on="on"
                          class="pa-0 pr-3"
                          style="width: 30px; height: 30px"
                        >
                          mdi-calendar
                        </v-icon>
                        <v-text-field
                          :value="getReadableDate(inspectionDate)"
                          label="Nächste TÜV/AU"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="inspectionDate"
                        :active-picker.sync="activePicker"
                        locale="de-DE"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-text-field label="SchlüsselNr." v-model="keyNr"></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-4">
                  <v-col cols="4" class="py-0"><h3>Fahrzeugschein</h3></v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <div class="imageUploadGridHelper">
                      <v-img
                        class="pb-4"
                        :src="registrationFromDB"
                        v-if="registrationFromDB && !registration"
                      >
                      </v-img>
                      <v-img class="imageUploader">
                        <image-uploader
                          :debug="0"
                          :maxWidth="2142"
                          :maxHeight="1615"
                          :quality="0.6"
                          :autoRotate="true"
                          outputFormat="verbose"
                          :preview="true"
                          capture="camera"
                          accept="image/*"
                          id="registration"
                          v-model="registration"
                        >
                        </image-uploader>
                        <label for="registration" class="imageUploadButton">
                          <v-icon class="pr-8" color="white">mdi-camera</v-icon>
                          <p style="width: 150px">Foto Hochladen</p>
                        </label>
                      </v-img>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <v-container fluid class="mt-auto">
                <v-row class="pt-4">
                  <v-spacer />
                  <v-col cols="4" class="py-0">
                    <v-btn color="primary" block @click="sendVehicleData"
                      >Speichern</v-btn
                    >
                  </v-col>
                  <v-spacer />
                </v-row>
              </v-container>
              <br />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { getReadableDate } from "../../js/helperFunctions.js";
import ImageUploader from "vue-image-upload-resize";

const vehicleManufacturers = require("./VehicleData.json");

const axios = require("axios");

export default {
  name: "newVehicle",

  props: ["customerData", "vehicleData"],

  data() {
    return {
      plate: undefined,

      manufacturer: undefined,
      manufacturers: vehicleManufacturers.manufacturers,

      model: undefined,

      registration: undefined,
      inspectionDate: "",
      keyNr: undefined,

      inspectionDateMenu: false,
      activePicker: "YEAR",

      state: "main",

      requiredRules: [(v) => !!v || "Pflichtfeld!"],
      integerRules: [(v) => !v || !isNaN(parseInt(v)) || "Gültige Zahl eingeben!"],
      plateRules: [(v) => this.checkValidPlate(v) || "Falsches Format"],

      registrationFromDB: undefined,

      title: "Neues Fahrzeug",
      name: undefined,
    };
  },

  components: {
    ImageUploader,
  },

  computed: {
    colLimit() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 10;
        case "md":
          return 8;
        case "lg":
          return 9;
        case "xl":
          return 6;
        default:
          return 8;
      }
    },
  },

  watch: {
    inspectionDateMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  methods: {
    checkValidPlate() {
      if (!this.plate) return false;

      let plateArray = this.plate.split(/-| /);

      if (plateArray.length != 3) {
        return false;
      }

      return true;
    },
    checkAndCorrectPlate() {
      let plateArray = this.plate.split(/-| /);

      if (plateArray.length < 3) {
        return;
      }

      this.plate = plateArray[0] + "-" + plateArray[1] + " " + plateArray[2];
    },
    writeVehicleManufacturerByEvent(event) {
      this.manufacturer = event.target.value;
    },
    writeVehicleManufacturerByInput(val) {
      this.manufacturer = val;
    },
    getReadableDate: getReadableDate,
    closeOverlay(data) {
      this.$emit("hide", data);
    },
    sendVehicleData() {
      if (this.$refs.vehiclePostForm.validate()) {
        this.checkAndCorrectPlate();

        if (this.keyNr == "null") this.keyNr = undefined;

        let formData = new FormData();
        formData.append("plate", this.plate);
        formData.append("model", this.manufacturer);
        formData.append("type", this.model);
        formData.append("key_nr", this.keyNr);
        formData.append(
          "inspection_date",
          this.inspectionDate ? this.inspectionDate : ""
        );
        formData.append("Customer", this.customerData.id);

        if (this.registration) {
          var dataURL = this.registration.dataUrl;
          dataURL = dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
          var blob = this.base64toBlob(dataURL, "image/jpeg");

          formData.append("registration", blob, "registration.jpg");
        }

        axios
          .post(axios.defaults.baseURL + "vehicles/new/", formData)
          .then((resp) => {
            this.closeOverlay(resp.data);
          })
          .catch((e) => console.log(e));
      }
    },
    base64toBlob(base64Data, contentType) {
      contentType = contentType || "";
      var sliceSize = 1024;
      var byteCharacters = atob(base64Data);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    saveBirthday(date) {
      this.$refs.birthdayMenu.save(date);
    },
  },

  mounted() {
    this.name = this.customerData.first_name + " " + this.customerData.last_name;
  },
};
</script>
