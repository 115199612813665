import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/Main/Login.vue";
// import store from '../store'
import dashboard from "../views/Main/Dashboard.vue";
import mainPage from "../views/Main/MainPage.vue";
import customers from "../views/Customer/Customer.vue";
import appointments from "../views/Appointment/Appointment.vue";
import appointmentDayList from "../views/Appointment/AppointmentDayList.vue";
import months from "../views/Appointment/Months.vue";
// import storage from "../views/Storage/Storage.vue";
import admin from "../views/Admin/Admin.vue";
import mainCustomer from "../components/Customer/MainCustomer.vue";
import customerDetail from "../components/Customer/CustomerDetail.vue";
import vehicleDetail from "../components/Vehicle/VehicleDetail.vue";
import new_storage from "../views/Storage/New_Storage.vue";
import AppointmentLogin from "@/components/Appointments/AppointmentLogin.vue";
import NewAppointmentSlots from '@/components/Appointments/NewAppointmentSlots.vue';
import AppointmentSlotOverview from '@/views/Appointment/AppointmentSlotOverview.vue';
import AppointmentBooking from '@/views/Appointment/AppointmentBooking.vue'
import Fuhrpark from '@/views/Fuhrpark/Fuhrpark.vue'

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: login,
    beforeEnter(to, from, next) {
      document.title = "ReifenReibold";
      next();
    },
  },
  {
    path: "/dashboard",
    component: mainPage,
    beforeEnter(to, from, next) {
      if (store.state.accessToken && store.state.permission != 6) {
        next();
      } else {
        next("/");
      }
    },

    children: [
      {
        path: "",
        component: dashboard,
      },
      {
        path: "/kunden",
        component: customers,
        children: [
          {
            path: "/kunden",
            component: mainCustomer,
          },
          {
            path: "/kunden/:id/:newVehicle?",
            component: customerDetail,
          },
          {
            path: "/fahrzeug/:customer/:vehicle",
            component: vehicleDetail,
          },
        ],
      },
      {
        path: "/termine/:datum?",
        component: appointments,
      },
      {
        path: "/heute",
        component: appointmentDayList,
      },
      {
        path: "/uebersicht",
        component: months,
      },
      // {
      //   path: "/storage/:kunde?/:fahrzeug?/:reifen?",
      //   component: storage,
      // },
      {
        path: "/lager/:kunde?/:fahrzeug?/:reifen?",
        component: new_storage,
      },
      {
        path: "/AppointmentLogin",
        component: AppointmentLogin,
      },
      {
        path: "/zeitplan",
        component: AppointmentSlotOverview,
      },
      {
        path: '/zeitplan/neu',
        component: NewAppointmentSlots,
      },
      {
        path: "/zeitplan/:id",
        component: NewAppointmentSlots,
      },
      {
        path: "/admin",
        component: admin,
      },
     
    ],
  },
  {
    path: '/fuhrpark',
    component: Fuhrpark,
    beforeEnter(to, from, next) {
      if (store.state.accessToken && store.state.permission == "6") {
        next();
      } else {
        next("/");
      }
    }
  },
  {
    path: '/terminbuchung',
    component: AppointmentBooking,
  },
  
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

export default router;
