<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="vehicles"
          :search="query"
          :items-per-page="20"
          id="fuhrpark-table"
          :footer-props="{ itemsPerPageOptions: pageSizes }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="query"
              label="Suche"
              class="mx-4"
              outlined
              style="width: 50%"
            ></v-text-field>
          </template>

          <template v-slot:[`item.tire_type`]="{ item }">
            <div
              style="display:flex; justify-content: center; align-items: center"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    v-on="on"
                    v-bind="attrs"
                    style="flex-grow: 0"
                    width="32px"
                    :src="getFelge(item.tire_type)"
                  ></v-img>
                </template>
                {{
                  item.tire_type == 0
                    ? "Alu"
                    : item.tire_type == 1
                    ? "Stahl"
                    : item.tire_type == 2
                    ? "Keine"
                    : ""
                }}
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.tire_season`]="{ item }">
            <div
              style="display:flex; justify-content: center; align-items: center"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    v-on="on"
                    v-bind="attrs"
                    style="flex-grow: 0"
                    width="32px"
                    :src="getSeason(item.tire_season)"
                  ></v-img>
                </template>
                {{
                  item.tire_season == 0
                    ? "Allwetter"
                    : item.tire_season == 1
                    ? "Sommer"
                    : item.tire_season == 2
                    ? "Winter"
                    : ""
                }}
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios");

export default {
  data() {
    return {
      query: undefined,
      vehicles: undefined,

      pageSizes: [10, 20, 50, "all"],

      headers: [
        { text: "Kennzeichen", value: "plate" },
        { text: "Autotyp", value: "type" },
        { text: "SchlüsselNr", value: "key_nr" },
        { text: "Letzter TÜV", value: "last_tuv" },
        { text: "Nächster TÜV", value: "next_tuv" },
        { text: "Reifensatz", value: "tire_manufacturer" },
        { text: "Felge", value: "tire_type", width: "6%", filterable: false},
        { text: "Saison", value: "tire_season", width: "7%", filterable: false},
        { text: "Reifenprofil Vorne", value: "tire_profile_f", filterable: false },
        { text: "Reifenprofil Hinten", value: "tire_profile_r", filterable: false },
        { text: "letzter Reifenwechsel", value: "last_tire_change" },
      ],
    };
  },
  methods: {
    fetchFleet() {
      axios
        .get(axios.defaults.baseURL + "vehicles/fleet/")
        .then((resp) => {
          this.vehicles = resp.data.vehicles;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getFelge(type) {
      if (type == 0) return require("../../../assets/icons/type_alu.svg");
      else if (type == 1)
        return require("../../../assets/icons/type_stahl.svg");
      else if (type == 2)
        return require("../../../assets/icons/type_keine.svg");
      else return "";
    },
    getSeason(season) {
      if (season == 0)
        return require("../../../assets/icons/season_allwetter.svg");
      else if (season == 1)
        return require("../../../assets/icons/season_sommer.svg");
      else if (season == 2)
        return require("../../../assets/icons/season_winter.svg");
      else return "";
    },
  },

  mounted() {
    this.fetchFleet();
  },
};
</script>
