var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column-reverse"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"display":"flex","flex-direction":"column-reverse"}},'span',attrs,false),on),_vm._l((_vm.rowData.height),function(index){return _c('span',{key:index,staticStyle:{"margin-bottom":"2px"}},[_c('v-img',{staticClass:"tireImage",attrs:{"width":_vm.imageWidth,"src":_vm.placeData.space_filled >= index
              ? require('@/assets/images/reifenstapel.svg')
              : require('@/assets/images/Platzhalter.svg')}})],1)}),0)]}}])},[_vm._v(" R"+_vm._s(_vm.rowData.number)+" P"+_vm._s(_vm.placeData.number)+" ")]),(_vm.placeData.space_filled > _vm.rowData.height)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,false,2607729887)},[_vm._v(" "+_vm._s(_vm.placeData.space_filled)+" Reifensätze ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }