<template>
  <div id="dashboard">
    <v-container
      :class="{
        centerOnScreen: smallerContainer ? true : false,
        dashBoardSmallScreen: smallerContainer ? false : true,
      }"
    >
      <v-row
        style="display: flex; justify-content: space-around; margin: 0 auto"
        :style="{ 'max-width': smallerContainer ? '75%' : '100%' }"
      >
        <v-col :cols="cols">
          <v-hover v-slot="{ hover }">
            <v-card
              @click="gotoNewCustomer"
              :elevation="hover ? 16 : 2"
              height="260px"
            >
              <v-container
                fluid
                class="text-center pt-1"
                style="
                  height: 204px;
                  display: flex;
                  justify-content: space-evenly;
                  flex-direction: column;
                "
              >
                <v-row class="mt-4">
                  <v-spacer />
                  <v-col class="pa-0 mx-0 mt-15" cols="4">
                    <p class="pa-0 ma-0" color="primary">Kunden</p>
                  </v-col>
                  <v-col cols="4" class="pa-0 mx-0 mt-15">
                    <p class="pa-0 ma-0" color="primary">Fahrzeuge</p>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col class="pt-0 mb-10" cols="4">
                    <h2>{{ customerCount }}</h2>
                  </v-col>
                  <v-col class="pt-0 mb-10" cols="4">
                    <h2>{{ vehicleCount }}</h2>
                  </v-col>
                  <v-spacer />
                </v-row>
              </v-container>
              <v-divider />
              <v-card-text class="text-center">
                <div class="alignSameRow clickable">
                  <div><b>Kunden</b></div>
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
        <v-col :cols="cols" v-if="checkPerm(4)">
          <v-hover v-slot="{ hover }">
            <v-card
              height="260px"
              :elevation="hover ? 16 : 2"
              @click="gotoAppointments()"
            >
              <v-container fluid class="text-center" style="height: 204px">
                <v-row class="mt-4">
                  <v-spacer />
                  <v-col class="pa-0 mx-0 mt-15" cols="4">
                    <p class="pa-0 ma-0" color="primary">Woche</p>
                  </v-col>
                  <v-col cols="4" class="pa-0 mx-0 mt-15">
                    <p class="pa-0 ma-0" color="primary">Heute</p>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col class="pt-0 mb-10" cols="4">
                    <h2>{{ appointmentsCount_week }}</h2>
                  </v-col>
                  <v-col class="pt-0 mb-10" cols="4">
                    <h2>{{ appointmentsCount_day }}</h2>
                  </v-col>
                  <v-spacer />
                </v-row>
              </v-container>
              <v-divider />
              <v-card-text class="text-center"><b>Termine</b></v-card-text>
            </v-card>
          </v-hover>
        </v-col>
        <v-col :cols="cols" v-if="checkPerm(2)">
          <v-hover v-slot="{ hover }">
            <v-card
              height="260px"
              :elevation="hover ? 16 : 2"
              @click="gotoStorage()"
            >
              <v-container fluid class="text-center" style="height: 204px">
                <v-row class="mt-4">
                  <v-spacer />
                  <v-col class="pa-0 mx-0 mt-15" cols="4">
                    <p class="pa-0 ma-0" color="primary">Kapazität</p>
                  </v-col>
                  <v-col cols="4" class="pa-0 mx-0 mt-15">
                    <p class="pa-0 ma-0" color="primary">Frei</p>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col class="pt-0 mb-10" cols="4">
                    <h2>{{ totalSpaces }}</h2>
                  </v-col>
                  <v-col class="pt-0 mb-10" cols="4">
                    <h2>{{ totalSpaces - occupiedSpaces }}</h2>
                  </v-col>
                  <v-spacer />
                </v-row>
              </v-container>
              <v-divider />
              <v-card-text class="text-center"><b>Lager</b></v-card-text>
            </v-card>
          </v-hover>
        </v-col>
        <!-- <v-spacer />
      </v-row>
      <v-row>
        <v-spacer /> -->
        <v-col :cols="cols">
          <v-hover v-slot="{ hover }">
            <v-card height="260px" :elevation="hover ? 16 : 2">
              <v-container
                fluid
                class="text-center"
                :class="{ innerCard: expiredTires.length > 3 }"
                style="height: 204px"
              >
                <p v-if="expiredTires.length == 0" style="color: lightgray">
                  Keine Reifen länger als 2 Jahre im Lager!
                </p>
                <div
                  v-for="tire in expiredTires"
                  :key="tire.id"
                  @click="
                    gotoVehicle(tire.Vehicle.Customer.id, tire.Vehicle.id)
                  "
                >
                  <v-row class="ma-0 pa-0">
                    <v-col class="ma-0 pa-0">
                      <div
                        style="display: flex; justify-content: center; font-size: large"
                        class="text-center"
                      >
                        <b class="pr-2">{{ tire.Vehicle.plate }}</b>
                        {{ tire.Vehicle.model }}
                      </div>
                      <div style="display: flex; justify-content: center">
                        <div>{{ tire.tire_storage + "," }}</div>
                        <b class="ml-2"
                          >seit {{ getReadableDate(tire.storage_date) }}</b
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-1" />
                </div>
              </v-container>

              <v-divider />
              <v-card-text class="text-center">
                <div><b>Reifenlagerzeit</b></div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>

        <v-col :cols="cols" v-if="checkPerm(4)">
          <v-hover v-slot="{ hover }">
            <v-card height="260px" :elevation="hover ? 16 : 2">
              <!-- <v-container
                fluid
                class="text-center"
                :class="{ innerCard: birthdays.length > 3 }"
                style="height: 204px"
              >
                <p v-if="birthdays.length == 0" style="color: lightgray">
                  Heute keine Geburtstage!
                </p>
                <div v-for="b in birthdays" :key="b.id" @click="gotoCustomers(b.id)">
                  <v-row class="ma-0 pa-0">
                    <v-col class="ma-0 pa-0">
                      <div style="font-size: large" class="text-center">
                        <b class="pr-2">{{ b.first_name + " " + b.last_name }}</b>
                      </div>
                      Wird heute {{ b.age }} !
                    </v-col>
                  </v-row>
                  <v-divider class="mb-1" />
                </div>
              </v-container> -->
              <v-container
                v-if="recentRequests.length > 0"
                fluid
                class="text-center pt-1"
                style="
                  height: 204px;
                  display: flex;
                  justify-content: space-evenly;
                  flex-direction: column;
                "
              >
                <v-row
                  v-for="request in recentRequests"
                  :key="request.id"
                  class="pa-0 mt-0"
                >
                  <v-spacer />
                  <v-col class="pb-0 pt-1 px-4" cols="12">
                    <v-btn
                      text
                      class="px-0"
                      @click="showRequestInCal(request, $event)"
                      style="display: flex; justify-content: space-between"
                      block
                    >
                      <div style="letter-spacing: normal">
                        <v-icon color="primary">mdi-calendar-blank</v-icon>
                        {{ request.Vehicle.plate }}
                      </div>
                      <div style="letter-spacing: normal" class="pb-1 pr-1">
                        {{ getReadableDate(request.date) }}
                      </div>
                    </v-btn>
                  </v-col>
                  <v-spacer />
                </v-row>
              </v-container>
              <v-divider />
              <v-card-text class="text-center"
                ><b>Offene Terminanfragen</b></v-card-text
              >
            </v-card>
          </v-hover>
        </v-col>
        <v-col :cols="cols">
          <v-hover v-slot="{ hover }">
            <v-card height="260px" :elevation="hover ? 16 : 2">
              <v-container
                fluid
                class="text-center"
                :class="{ innerCard: tuv.length > 3 }"
                style="height: 204px"
              >
                <p v-if="tuv.length == 0" style="color: lightgray">
                  Keine Kunden mit TÜV/AU in den nächsten 30 Tagen!
                </p>
                <div v-for="t in tuv" :key="t.id">
                  <v-row
                    class="ma-0 pa-0"
                    @click="gotoVehicle(t.Customer, t.id)"
                  >
                    <v-col class="ma-0 pa-0">
                      <div
                        style="display: flex; justify-content: center; font-size: large"
                        class="text-center"
                      >
                        <b class="pr-2">{{ t.plate }}</b>
                        {{ t.model }}
                      </div>
                      <div style="display: flex; justify-content: center">
                        <b class="ml-2"
                          >fällig {{ getReadableDate(t.inspection_date) }}</b
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-1" />
                </div>
              </v-container>
              <v-divider />
              <v-card-text class="text-center"
                ><b>TÜV/AU Fälligkeit</b></v-card-text
              >
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>

    <v-overlay :value="customerOverlayVisible" :dark="false">
      <NewCustomerOverlay
        @hide="customerOverlayVisible = false"
        @created="newCustomerCreated"
      />
    </v-overlay>
  </div>
</template>

<script>
import NewCustomerOverlay from "../../components/Customer/NewCustomer.vue";
import { getReadableDate } from "../../js/helperFunctions.js";

const axios = require("axios");
export default {
  name: "dashboard",

  components: {
    NewCustomerOverlay,
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 6;
        case "md":
          return 5;
        case "lg":
          return 4;
        case "xl":
          return 4;
        default:
          return 8;
      }
    },
    smallerContainer() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
        default:
          return false;
      }
    },
  },

  data() {
    return {
      customerCount: 0,
      vehicleCount: 0,
      appointmentsCount_week: 0,
      appointmentsCount_day: 0,
      occupiedSpaces: 0,
      totalSpaces: 0,
      customerOverlayVisible: false,

      expiredTires: [],
      birthdays: [],
      tuv: [],
      recentRequests: [],
    };
  },

  methods: {
    getReadableDate: getReadableDate,
    gotoAppointments() {
      this.$router.push("/termine");
    },
    gotoVehicle(customerID, vehicleID) {
      if (customerID != undefined && vehicleID != undefined) {
        this.$router.push("/fahrzeug/" + customerID + "/" + vehicleID);
      }
    },
    gotoCustomers(id) {
      if (id != undefined) this.$router.push("/kunden/" + id);
      else this.$router.push("/kunden");
    },
    newCustomerCreated(data) {
      this.gotoCustomers(data.id);
      this.customerOverlayVisible = false;
    },

    gotoStorage() {
      this.$router.push("/lager");
    },
    gotoNewCustomer(e) {
      this.customerOverlayVisible = true;

      e.stopPropagation();
    },

    checkPerm(val) {
      if (sessionStorage.getItem("permission") <= 2) return true;

      return sessionStorage.getItem("permission") == val;
    },
    fetchRecentRequests() {
      axios
        .get(axios.defaults.baseURL + "appointments/recent/")
        .then((resp) => {
          this.recentRequests = resp.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    showRequestInCal(request, event) {
      this.$router.push("/termine/" + request.date);

      event.stopPropagation();
      event.preventDefault();
    },
  },

  watch: {},

  mounted() {
    if (this.checkPerm(4)) {
      this.fetchRecentRequests();
    }

    let date = new Date();

    let today = date.toLocaleString().split(",")[0];
    let totalWeek = date.getTime();

    //depending on which day of the week it is add days left of that week
    //=> will display only appointments left this week

    switch (date.getDay()) {
      case 0:
        break; // sunday
      case 1:
        totalWeek += 6 * 86400000;
        break;
      case 2:
        totalWeek += 5 * 86400000;
        break;
      case 3:
        totalWeek += 4 * 86400000;
        break;
      case 4:
        totalWeek += 3 * 86400000;
        break;
      case 5:
        totalWeek += 2 * 86400000;
        break;
      case 6:
        totalWeek += 1 * 86400000;
        break;
    }

    totalWeek = new Date(totalWeek).toLocaleString().split(",")[0];

    axios
      .get(axios.defaults.baseURL + "customers/count")
      .then((resp) => {
        this.customerCount = resp.data.count;

        axios
          .get(axios.defaults.baseURL + "tires/expired")
          .then((resp) => {
            this.expiredTires = resp.data;

            axios
              .get(axios.defaults.baseURL + "vip/tuv")
              .then((resp2) => {
                this.tuv = resp2.data;

                // axios
                //   .get(axios.defaults.baseURL + "vip/birthdays/?date=" + today)
                //   .then((resp3) => {
                //     this.birthdays = resp3.data;

                //     let currYear = date.getFullYear();
                //     for (let i = 0; i < this.birthdays.length; i++) {
                //       let birthYear = this.birthdays[i].birthday.split("-")[0];

                //       this.birthdays[i].age = currYear - birthYear;
                //     }

                    axios
                      .get(axios.defaults.baseURL + "vehicles/count")
                      .then((resp4) => {
                        this.vehicleCount = resp4.data.count;

                        axios
                          .get(
                            axios.defaults.baseURL +
                              "appointments/by/date/?start=" +
                              today +
                              "&end=" +
                              today +
                              "&location=1"
                          )
                          .then((resp5) => {
                            this.appointmentsCount_day =
                              resp5.data.appointments.length +
                              resp5.data.temp_appointments.length;
                            if (this.checkPerm(4)) {
                              axios
                                .get(
                                  axios.defaults.baseURL +
                                    "appointments/by/date/?start=" +
                                    today +
                                    "&end=" +
                                    totalWeek +
                                    "&location=1"
                                )
                                .then((resp6) => {
                                  this.appointmentsCount_week =
                                    resp6.data.appointments.length +
                                    resp6.data.temp_appointments.length;

                                  axios
                                    .get(
                                      axios.defaults.baseURL +
                                        "storages/counted"
                                    )
                                    .then((resp7) => {
                                      for (
                                        let i = 0;
                                        i < resp7.data.length;
                                        i++
                                      ) {
                                        for (
                                          let j = 0;
                                          j < resp7.data[i].row_set.length;
                                          j++
                                        ) {
                                          this.occupiedSpaces +=
                                            resp7.data[i].row_set[
                                              j
                                            ].space_filled.count;
                                          this.totalSpaces +=
                                            resp7.data[i].row_set[
                                              j
                                            ].space_filled.total_spaces;
                                        }
                                      }
                                    })
                                    .catch((e) => {
                                      console.log(e);
                                    });
                                })
                                .catch((e) => console.log(e));
                            }
                          })
                          .catch((e) => {
                            console.log(e);
                          });
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  // })
                  // .catch((e) => console.log(e));
              })
              .catch((e) => console.log(e));
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>
