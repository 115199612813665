<template>
  <div>
    <v-card
      class="pa-10"
      style="height: fit-content; margin: 0 auto; max-height: 80vh; overflow-y: scroll"
      :style="{ width: cardWidth }"
    >
      <v-form ref="tireForm" class="containerSpacer">
        <v-btn class="overlayClose rounded-xl" text icon @click="emitClose(undefined)">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-container fluid>
          <v-row>
            <v-col :cols="8" class="py-0"
              ><h2>{{ title }}</h2></v-col
            >
            <v-spacer />
            <v-col cols="auto" class="py-0">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    style="font-size: xxx-large"
                    :style="{ color: replacement ? 'red' : 'rgba(0,0,0,0.55)' }"
                    @click="replacement = !replacement"
                  >
                    mdi-alert
                  </v-icon>
                </template>
                <span>Reifen kaputt?</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-0">
              <h3>Stammdaten</h3>
            </v-col>
          </v-row>

          <v-row>
            <v-col :cols="colWidth / 2" class="py-0">
              <v-text-field
                label="DOT"
                :rules="requiredRules"
                v-model="dot"
              ></v-text-field>
            </v-col>
            <v-col :cols="colWidth / 2" class="py-0">
              <v-text-field label="Wert in €" v-model="value"></v-text-field>
            </v-col>
            <v-col :cols="colWidth / 2" class="py-0">
              <v-select
                label="Saison"
                v-model="season"
                :items="seasons"
                :rules="requiredRules"
              >
                <template v-slot:item="{ item }">
                  <v-img :src="item.icon" class="svgIconScaler"></v-img>
                  {{ item.text }}
                </template>
                <template v-slot:selection="{ item }">
                  <v-img :src="item.icon" class="svgIconScaler"></v-img>
                  {{ item.text }}
                </template>
              </v-select>
            </v-col>

            <v-col :cols="colWidth / 2" class="py-0">
              <v-text-field label="Hersteller" v-model="manuf"></v-text-field>
            </v-col>
            <v-col :cols="colWidth / 2" class="py-0">
              <v-text-field label="Name" v-model="model"></v-text-field>
            </v-col>
            <v-col :cols="colWidth / 2" class="py-0">
              <v-select :items="types" v-model="type" label="Felgenart">
                <template v-slot:item="{ item }">
                  <v-img
                    v-if="!item.isIcon"
                    :src="item.icon"
                    class="svgIconScaler"
                  ></v-img>
                  <v-icon v-else>{{ item.icon }}</v-icon>
                  {{ item.text }}
                </template>
                <template v-slot:selection="{ item }">
                  <v-img
                    v-if="!item.isIcon"
                    :src="item.icon"
                    class="svgIconScaler"
                  ></v-img>
                  <v-icon v-else>{{ item.icon }}</v-icon>
                  {{ item.text }}
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row class="mt-4" v-if="!multiRow">
            <v-col cols="8" class="py-0">
              <h3>Abmessungen</h3>
            </v-col>
            <v-col cols="4" class="py-0">
              <h3>Profil</h3>
            </v-col>
          </v-row>

          <v-row v-if="!multiRow">
            <v-col cols="2" class="py-0">
              <v-text-field class="removeInputSpacing" v-model="width"></v-text-field>
            </v-col>
            <v-col cols="auto" style="display: flex; align-items: center">/</v-col>
            <v-col cols="1" class="py-0">
              <v-text-field class="removeInputSpacing" v-model="radius"></v-text-field>
            </v-col>
            <v-col cols="auto" style="display: flex; align-items: center">R</v-col>
            <v-col cols="1" class="py-0">
              <v-text-field
                class="removeInputSpacing"
                v-model="crossSection"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="py-0">
              <v-text-field
                class="removeInputSpacing"
                v-model="weightIndex"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="py-0">
              <v-text-field
                class="removeInputSpacing"
                v-model="speedIndex"
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="2" class="py-0">
              <v-text-field label="Vorderachse" v-model="profileF"></v-text-field>
            </v-col>
            <v-col cols="2" class="py-0">
              <v-text-field label="Hinterachse" v-model="profileR"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-4" v-if="multiRow">
            <v-col cols="12" class="py-0">
              <h3>Abmessungen</h3>
            </v-col>
          </v-row>

          <v-row v-if="multiRow">
            <v-col cols="2" class="py-0" :class="{ 'px-1': verySmall }">
              <v-text-field class="removeInputSpacing" v-model="width"></v-text-field>
            </v-col>
            <v-col
              cols="auto"
              style="display: flex; align-items: center"
              :class="{ 'px-1': verySmall }"
              >/</v-col
            >
            <v-col cols="2" class="py-0" :class="{ 'px-1': verySmall }">
              <v-text-field class="removeInputSpacing" v-model="radius"></v-text-field>
            </v-col>
            <v-col
              cols="auto"
              style="display: flex; align-items: center"
              :class="{ 'px-1': verySmall }"
              >R</v-col
            >
            <v-col cols="2" class="py-0" :class="{ 'px-1': verySmall }">
              <v-text-field
                class="removeInputSpacing"
                v-model="crossSection"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="py-0" :class="{ 'px-1': verySmall }">
              <v-text-field
                class="removeInputSpacing"
                v-model="weightIndex"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="py-0" :class="{ 'px-1': verySmall }">
              <v-text-field
                class="removeInputSpacing"
                v-model="speedIndex"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-4" v-if="multiRow">
            <v-col cols="4" class="py-0">
              <h3>Profil</h3>
            </v-col>
          </v-row>

          <v-row v-if="multiRow">
            <v-col cols="2" class="py-0">
              <v-text-field label="Vorderachse" v-model="profileF"></v-text-field>
            </v-col>
            <v-col cols="2" class="py-0">
              <v-text-field label="Hinterachse" v-model="profileR"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col :cols="colWidth / 2" class="py-0">
              <h3>Anmerkung</h3>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="py-0">
              <v-textarea
                solo
                label="max. 1000 Zeichen"
                v-model="annotation"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid class="mt-auto">
          <v-row class="pt-4">
            <v-spacer />
            <v-col :cols="colWidth / 2" class="py-0">
              <v-btn color="primary" block @click="emitSave">Speichern</v-btn>
            </v-col>
            <v-spacer />
            <v-col v-if="data" :cols="colWidth / 2" class="py-0">
              <v-btn color="error" block @click="showDeletionWarning = true"
                ><v-icon>mdi-delete</v-icon> Löschen
              </v-btn>
            </v-col>
            <v-spacer v-if="data" />
          </v-row>
        </v-container>

        <br />
      </v-form>
    </v-card>

    <div class="fullScreenOverlay" v-if="showDeletionWarning">
      <div class="overlayBackground"></div>
      <WarnBanner
        class="overlayDisplay"
        @cancel="showDeletionWarning = false"
        @approve="deleteFromDB()"
        :text="'diesen Reifensatz'"
        :action="'löschen'"
      />
    </div>
  </div>
</template>

<script>
import WarnBanner from "../Misc/WarnBanner.vue";

const axios = require("axios");

export default {
  name: "newTire",

  props: ["data"],

  data() {
    return {
      title: "Neuer Reifensatz",
      type: "Alu",
      dot: undefined,
      season: undefined,
      width: undefined,
      radius: undefined,
      crossSection: undefined,
      weightIndex: undefined,
      speedIndex: undefined,
      profileF: undefined,
      profileR: undefined,
      value: undefined,
      manuf: undefined,
      model: undefined,
      annotation: undefined,
      replacement: undefined,

      types: [
        {
          text: "Alu",
          icon: require("../../assets/icons/type_alu.svg"),
        },
        {
          text: "Stahl",
          icon: require("../../assets/icons/type_stahl.svg"),
        },
        {
          text: "Keine",
          icon: require("../../assets/icons/type_keine.svg"),
        },
      ],
      seasons: [
        {
          text: "Allwetter",
          icon: require("../../assets/icons/season_allwetter.svg"),
        },
        {
          text: "Sommer",
          icon: require("../../assets/icons/season_sommer.svg"),
        },
        {
          text: "Winter",
          icon: require("../../assets/icons/season_winter.svg"),
        },
      ],

      requiredRules: [(v) => !!v || "Pflichtfeld!"],

      showDeletionWarning: false,
    };
  },

  computed: {
    colLimit() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 10;
        case "md":
          return 8;
        case "lg":
          return 9;
        case "xl":
          return 6;
        default:
          return 8;
      }
    },
    colWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        case "lg":
          return 4;
        case "xl":
          return 3;
        default:
          return 8;
      }
    },
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "80vw !important";
        case "sm":
          return "80vw !important";
        case "md":
          return "65vw !important";
        case "lg":
          return "60vw !important";
        case "xl":
          return "40vw !important";
        default:
          return "40vw !important";
      }
    },
    multiRow() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    verySmall() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        default:
          return false;
      }
    },
  },

  components: {
    WarnBanner,
  },

  methods: {
    deleteFromDB() {
      console.log(this.data);
      if (this.data.in_storage) {
        window.alert("Eingelagerte Reifen können nicht gelöscht werden!");
        this.showDeletionWarning = false;
      } else {
        axios
          .delete(axios.defaults.baseURL + "tires/" + this.data.id)
          .then((resp) => {
            console.log(resp, "deleted");
            this.showDeletionWarning = false;
            this.$emit("deleted");
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    emitClose() {
      this.$emit("close");
    },
    emitSave() {
      if (this.$refs.tireForm.validate()) {
        let chosenSeason;
        for (let i = 0; i < this.seasons.length; i++) {
          if (this.seasons[i].text == this.season) {
            chosenSeason = i;
            break;
          }
        }

        let chosenType;
        for (let i = 0; i < this.types.length; i++) {
          if (this.types[i].text == this.type) {
            chosenType = i;
            break;
          }
        }

        let tireData = {
          type: chosenType,
          dot: this.dot,
          season: chosenSeason,
          width: this.width,
          radius: this.radius,
          cross_section: this.crossSection,
          weight_index: this.weightIndex,
          speed_index: this.speedIndex,
          profile_f: this.profileF,
          profile_r: this.profileR,
          value: this.value,
          manufacturer: this.manuf,
          model: this.model,
          annotation: this.annotation,
          replacement: this.replacement,
        };

        if (this.id) {
          tireData.id = this.id;
          axios
            .patch(axios.defaults.baseURL + "tires/simple/" + this.data.id, tireData)
            .then(() => {
              this.$emit("close", tireData);
            })
            .catch((e) => console.log(e));
        } else {
          tireData.Vehicle = this.$route.params.vehicle;
          axios
            .post(axios.defaults.baseURL + "tires/simple/", tireData)
            .then((resp) => {
              tireData.id = resp.data.id;
              this.$emit("close", tireData);
            })
            .catch((e) => console.log(e));
        }
      }
    },
  },

  mounted() {
    if (this.data) {
      this.type = this.types[this.data.type].text;
      this.dot = this.data.dot;
      this.season = this.seasons[this.data.season].text;
      this.width = this.data.width;
      this.radius = this.data.radius;
      this.crossSection = this.data.cross_section;
      this.weightIndex = this.data.weight_index;
      this.speedIndex = this.data.speed_index;
      this.profileF = this.data.profile_f;
      this.profileR = this.data.profile_r;
      this.value = this.data.value;
      this.manuf = this.data.manuf;
      this.model = this.data.model;
      this.annotation = this.data.annotation;
      this.id = this.data.id;
      this.replacement = this.data.replacement;

      this.title = "Reifensatz editieren";
    }
  },
};
</script>
