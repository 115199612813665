<template>
  <div>
    <v-app-bar fixed app color="toolbar" v-if="bigScreen" height="55px">
      <v-container style="height: 44px" fluid class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
          <v-col
            :cols="headerCols[0]"
            class="pa-0 ma-0"
            style="height: 44px; display: flex"
          >
            <button @click="gotoDashboard()" tag="button" class="headerLink">
              <v-img
                src="../../assets/images/ReifenReiboldLogo.png"
                contain
                height="40px"
                width="auto"
                class=""
                id="headerImg"
              ></v-img>
            </button>
          </v-col>
          <v-spacer />
          <v-col cols="3" class="pa-0 ma-0" style="height: 44px">
            <searchBar
              class="hideInputBottomSpacing pa-0 headerSearchBar"
              @customerClick="handleCustomerClick"
              @vehicleClick="handleVehicleClick"
              @customerCreated="gotoCustomer"
            />
          </v-col>
          <v-spacer />
          <v-col v-if="checkPerm(4) || checkPerm(5)" cols="auto" class="pa-0 ma-0 mx-1" style="height: 44px">
            <v-btn
              @click="gotoAppointments"
              class="rounded-lg"
              :class="{
                outlinedButton: isAppointmentView,
                basicButton: !isAppointmentView,
              }"
              ><v-icon>mdi-calendar</v-icon></v-btn
            >
          </v-col>
          <v-col v-if="checkPerm(3) || checkPerm(5)" cols="auto" class="pa-0 ma-0 mx-1" style="height: 44px">
            <v-btn
              @click="gotoAppDayList"
              class="rounded-lg"
              :class="{
                outlinedButton: isAppDayView,
                basicButton: !isAppDayView,
              }"
              ><v-icon>mdi-wrench-outline</v-icon></v-btn
            >
          </v-col>
          <v-col v-if="checkPerm(2)" cols="auto" class="pa-0 ma-0 mx-1" style="height: 44px">
            <v-btn
              @click="gotoStorage"
              class="rounded-lg"
              :class="{
                outlinedButton: isStorageView,
                basicButton: !isStorageView,
              }"
              ><v-icon>mdi-warehouse</v-icon></v-btn
            >
          </v-col>
          <v-col v-if="checkPerm(2) || checkPerm(5)" cols="auto" class="pa-0 ma-0 mx-1" style="height: 44px">
            <v-btn
              @click="gotoSchedules"
              class="rounded-lg"
              :class="{
                outlinedButton: isScheduleView,
                basicButton: !isScheduleView,
              }"
              ><v-icon>mdi-calendar-account</v-icon></v-btn
            >
          </v-col>
          <v-spacer />
          <v-col cols="auto" class="pa-0 ma-0" style="height: 44px">
            <v-btn
              style="
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                pointer-events: none;
              "
              text
              color="primary"
              class="hideButtonEffect"
            >
              <p class="my-3 text-no-wrap text-truncate">Hallo, {{ name }}</p>
            </v-btn>
          </v-col>
          <v-col class="pa-0 ma-0" style="height: 44px">
            <v-btn
              @click="logOut"
              text
              color="primary"
              class="hideButtonEffect"
            >
              <v-icon class="pr-3" id="logoutIcon" style="font-size: xx-large"
                >mdi-logout</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-app-bar fixed app color="toolbar" style="height: 106px" v-else>
      <v-container style="height: 88px" fluid class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
          <v-col
            :cols="headerCols[0]"
            class="pa-0 ma-0"
            style="height: 44px; display: flex"
          >
            <button @click="gotoDashboard()" tag="button" class="headerLink">
              <v-img
                src="../../assets/images/ReifenReiboldLogo.png"
                contain
                height="20px"
                width="auto"
                class=""
                id="headerImg"
              ></v-img>
            </button>
          </v-col>
          <v-spacer />
          <v-col cols="auto" class="pa-0 ma-0" style="height: 44px">
            <v-btn
              style="
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                pointer-events: none;
              "
              text
              color="primary"
              class="hideButtonEffect"
            >
              <p class="my-3 text-no-wrap text-truncate">Hallo, {{ name }}</p>
            </v-btn>
          </v-col>
          <v-col class="pa-0 ma-0" style="height: 44px" cols="auto">
            <v-btn
              @click="logOut"
              text
              color="primary"
              class="hideButtonEffect"
            >
              <v-icon class="pr-3" id="logoutIcon" style="font-size: xx-large">
                mdi-logout
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="4" class="pa-0 ma-0" style="height: 44px">
            <searchBar
              class="hideInputBottomSpacing pa-0 headerSearchBar"
              @customerClick="handleCustomerClick"
              @vehicleClick="handleVehicleClick"
              @customerCreated="gotoCustomer"
            />
          </v-col>
          <v-spacer />
          <v-col v-if="checkPerm(4) || checkPerm(5)" cols="auto" class="pa-0 ma-0 mx-1" style="height: 44px">
            <v-btn
              @click="gotoAppointments"
              class="rounded-lg"
              :class="{
                outlinedButton: isAppointmentView,
                basicButton: !isAppointmentView,
              }"
              ><v-icon>mdi-calendar</v-icon></v-btn
            >
          </v-col>
          <v-col v-if="checkPerm(3) || checkPerm(5)" cols="auto" class="pa-0 ma-0 mx-1" style="height: 44px">
            <v-btn
              @click="gotoAppDayList"
              class="rounded-lg"
              :class="{
                outlinedButton: isAppDayView,
                basicButton: !isAppDayView,
              }"
              ><v-icon>mdi-wrench-outline</v-icon></v-btn
            >
          </v-col>
          <v-col v-if="checkPerm(2)" cols="auto" class="pa-0 ma-0 mx-1" style="height: 44px">
            <v-btn
              @click="gotoStorage"
              class="rounded-lg"
              :class="{
                outlinedButton: isStorageView,
                basicButton: !isStorageView,
              }"
              ><v-icon>mdi-warehouse</v-icon></v-btn
            >
          </v-col>
          <v-col v-if="checkPerm(2) || checkPerm(5)" cols="auto" class="pa-0 ma-0 mx-1" style="height: 44px">
            <v-btn
              @click="gotoSchedules"
              class="rounded-lg"
              :class="{
                outlinedButton: isScheduleView,
                basicButton: !isScheduleView,
              }"
              ><v-icon>mdi-calendar-account</v-icon></v-btn
            >
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import searchBar from "../Header/SearchBar.vue";

const axios = require("axios");

export default {
  name: "headerComponent",

  components: {
    searchBar,
  },

  data() {
    return {
      name: sessionStorage.getItem("name"),
    };
  },

  computed: {
    isStorageView() {
      return this.$route.path.includes("lager");
    },
    isAppointmentView() {
      return this.$route.path.includes("termine");
    },
    isAppDayView() {
      return this.$route.path.includes("heute");
    },
    isScheduleView() {
      return this.$route.path.includes("zeitplan");
    },

    headerCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return [2, 4];
        case "sm":
          return [2, 4];
        case "md":
          return [1, 4];
        case "lg":
          return [3, 3];
        case "xl":
          return [3, 3];
        default:
          return 8;
      }
    },
    bigScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
        default:
          return false;
      }
    },
  },

  methods: {
    checkPerm(val) {
      if (sessionStorage.getItem("permission") <= 2) return true;

      return sessionStorage.getItem("permission") == val;
    },
    gotoCustomer(data) {
      this.$router.push("kunden/" + data.id);
    },
    handleCustomerClick(data) {
      this.$router.push("/kunden/" + data.item.customer.id);
    },
    handleVehicleClick(data) {
      if (data.vehicleID != 0) {
        this.$router.push(
          "/fahrzeug/" + data.item.customer.id + "/" + data.vehicleID
        );
        this.$router.go();
      } else {
        this.$router.push("/kunden/" + data.item.customer.id + "/neu");
        this.$router.go();
      }
    },
    logOut() {
      this.$store.dispatch("logoutUser");
    },
    gotoDashboard() {
      this.$router.push("/dashboard");
    },
    gotoStorage() {
      this.$router.replace("/lager");
    },
    gotoAppointments() {
      this.$router.replace("/termine");
    },
    gotoAppDayList() {
      this.$router.replace("/heute");
    },
    gotoSchedules() {
      this.$router.replace("/zeitplan");
    },
    gotoFleetList(){
      this.$router.replace("/fuhrpark");
    }
  },

  mounted() {
    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "users/search/?id=" +
          sessionStorage.getItem("mail")
      )
      .then((response) => {
        this.name = response.data[0].first_name;
        sessionStorage.setItem("name", response.data[0].first_name);

        let oldVal = sessionStorage.getItem("permission");
        sessionStorage.setItem("permission", response.data[0].permission);

        if (oldVal != response.data[0].permission) {
          window.location.reload();
        }

        localStorage.setItem("id", response.data[0].id);
      })
      .catch((error) => console.log(error));

    this.$store.state.showNewCustomer = false;
  },
};
</script>
