<template>
  <div
    id="newTempCustomer"
    style="width: fit-content; height: fit-content; display: flex; align-items: center"
  >
    <v-card
      class="pa-10"
      style="width: 40vw !important; height: fit-content; margin: 0 auto"
    >
      <v-btn class="overlayClose rounded-xl" text icon>
        <v-icon @click="closeOverlay">mdi-close</v-icon>
      </v-btn>
      <v-form ref="tempVehicleForm">
        <v-container fluid>
          <v-row>
            <v-col>
              <h2 class="text-center">Temporärer Kunde</h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="py-0"><h3>Fahrzeugdaten</h3></v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-combobox
                :rules="plateRules"
                label="Kennzeichen oder Name*"
                :value="plate"
                @update:search-input="updatePlate"
                :items="plateAutofills"
                item-text="plate"
              >
                <template v-slot:item="{ item }">
                  <div
                    style="display: flex; flex-direction: column; width: 100%"
                    class="px-3"
                    @click="chooseVehicle(item)"
                  >
                    <b>{{ item.plate }}</b>
                    {{
                      (item.customer_first_name || "") +
                      " " +
                      (item.customer_last_name || "")
                    }}
                  </div>
                </template>
              </v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="py-0"><h3>Persönliche Daten</h3></v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-0">
              <v-select
                :items="salutations"
                label="Anrede"
                v-model="salutation"
              ></v-select>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field label="Name" v-model="lastName"></v-text-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field label="Vorname" v-model="firstName"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="py-0"><h3>Kontaktdaten</h3> </v-col></v-row
          >
          <v-row>
            <v-col cols="4" class="py-0">
              <v-text-field label="E-Mail" v-model="mail"></v-text-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field label="Telefon" v-model="phone"></v-text-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field label="Mobil" v-model="mobile"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" class="py-0" style="display: flex; align-items: center">
              <v-switch
                class="my-1"
                v-model="sendNotification"
                inset
                label="Erinnerungs E-Mail?"
              ></v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-spacer />
            <v-col cols="4" class="py-0">
              <v-btn color="primary" block @click="sendCustomerData">Speichern</v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { getReadableDate } from "../../js/helperFunctions.js";

const axios = require("axios");

export default {
  name: "newTempCustomer",

  data() {
    return {
      salutations: ["Herr", "Frau", "Divers"],

      salutation: undefined,
      lastName: undefined,
      firstName: undefined,
      mail: undefined,
      phone: undefined,
      mobile: undefined,
      sendNotification: false,
      plate: undefined,

      plateAutofills: ["test", "plate"],
      searchResults: [],

      requiredRules: [(v) => !!v || "Pflichtfeld!"],
      plateRules: [(v) => !!v || "Falsches Format"],

      selectedVehicle: undefined,
    };
  },

  methods: {
    chooseVehicle(vehicle) {
      this.selectedVehicle = vehicle;

      this.plate = vehicle.plate;
      this.firstName = vehicle.customer_first_name;
      this.lastName = vehicle.customer_last_name;
      this.salutation = this.salutations[vehicle.salutation];
      this.mail = vehicle.mail;
      this.phone = vehicle.phone;
      this.mobile = vehicle.mobile;
      this.sendNotification = vehicle.send_notification_mail;
    },
    updatePlate(val) {
      this.plate = val;

      if (this.selectedVehicle && this.selectedVehicle.plate != val) {
        this.selectedVehicle = undefined;
      }

      if (this.plate && this.plate.length >= 2) {
        axios
          .get(axios.defaults.baseURL + "tempVehicles/search/?plate=" + this.plate)
          .then((resp) => {
            this.plateAutofills = resp.data;
          });
      } else {
        this.plateAutofills = [];
      }
    },
    checkValidPlate() {
      if (!this.plate) return false;

      let plateArray = this.plate.split(/-| /);

      if (plateArray.length != 3) {
        return false;
      }

      return true;
    },
    checkAndCorrectPlate() {
      let plateArray = this.plate.split(/-| /);

      if (plateArray.length < 3) {
        return;
      }

      this.plate = plateArray[0] + "-" + plateArray[1] + " " + plateArray[2];
    },
    getReadableDate: getReadableDate,
    closeOverlay() {
      this.$emit("hide", true);
    },
    sendCustomerData() {
      this.checkAndCorrectPlate();

      if (this.$refs.tempVehicleForm.validate()) {
        let formData = {
          salutation:
            this.salutations.indexOf(this.salutation) < 0
              ? null
              : this.salutations.indexOf(this.salutation),
          customer_first_name: this.firstName,
          customer_last_name: this.lastName,
          mail: this.mail,
          phone: this.phone,
          mobile: this.mobile,
          send_notification_mail: this.sendNotification,
          plate: this.plate,
        };

        if (this.selectedVehicle) {
          formData.id = this.selectedVehicle.id;

          axios
            .patch(axios.defaults.baseURL + "tempVehicles/" + formData.id, formData)
            .then((resp) => {
              this.$emit("created", resp.data);
            })
            .catch((e) => console.log(e));
        } else {
          axios
            .post(axios.defaults.baseURL + "tempVehicles/", formData)
            .then((resp) => {
              this.$emit("created", resp.data);
            })
            .catch((e) => console.log(e));
        }
      }
    },
  },
};
</script>
