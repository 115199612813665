<template>
  <div id="admin">
    <v-container fluid>
      <v-row>
        <v-spacer />
        <v-col cols="6"> Enter Datenbank Here: </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="6">
          <v-file-input
            accept="json"
            block
            truncate-length="15"
            v-model="file"
            @change="uploadToDB"
          ></v-file-input>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>

    <hr />

    <v-container fluid>
      <v-row>
        <v-spacer />
        <v-col cols="6"> Output: </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="3"> Gesendete Kunden: {{ customersSent }} </v-col>
        <v-spacer />
        <v-col cols="3"> Gesendete Fahrzeuge: {{ vehiclesSent }} </v-col>
        <v-spacer />
        <v-col cols="3"> Gesendete Reifen: {{ tiresSent }} </v-col>
        <v-spacer />
        <v-col cols="3"> Gesendete Storages: {{ storagesSent }} </v-col>
      </v-row>
    </v-container>

    <hr />

    <v-container fluid>
      <v-row>
        <v-spacer />
        <v-col cols="6"> Error: </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="12" v-for="(e, index) in errorMsg" :key="index">
          Log: {{ e.msg }}
          <span v-for="(d, dIndex) in e.data" :key="dIndex">{{ d }},</span>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "admin",

  data() {
    return {
      file: undefined,

      customersSent: 0,
      vehiclesSent: 0,
      tiresSent: 0,
      storagesSent: 0,

      errorMsg: [],

      oldToNewCustomerMapping: [],
      oldToNewVehicleMapping: [],
      oldToNewTireMapping: [],

      parsedData: undefined,
      state: 0,

      warehouses: undefined,
    };
  },

  watch: {
    state(val) {
      switch (val) {
        case 0:
          break;
        case 1:
          setTimeout(() => {
            console.log("start sending customers");
            this.collectAndSendCustomersSetup(this.parsedData);
          }, 1000);
          break;
        case 2:
          setTimeout(() => {
            console.log("start sending vehicles");
            this.collectAndSendVehiclesSetup(this.parsedData);
          }, 1000);
          break;
        case 3:
          setTimeout(() => {
            console.log("start sending tires");
            this.collectAndSendTiresSetup(this.parsedData);
          }, 1000);
          break;
        case 4:
          setTimeout(() => {
            console.log("start sending storages");
            this.collectAndSendStorageSetup(this.parsedData);
          }, 1000);
          break;
        case 5:
          setTimeout(() => {
            console.log("start connecting storages");
            this.setupConnectTiresToStorage(this.parsedData);
          }, 1000);
          break;
      }
    },
  },

  methods: {
    uploadToDB() {
      var reader = new FileReader();
      reader.onload = this.onReaderLoad;
      reader.readAsText(this.file);
    },

    onReaderLoad(event) {
      this.parsedData = JSON.parse(event.target.result);
      console.log(this.parsedData);

      //this.parsedDataects will be created in the following order:
      //Customer
      //Vehicles -- connected to customer
      //Tires -- connected to vehicle
      //Storage
      //connect tire to space

      ++this.state;
    },

    collectAndSendCustomersSetup() {
      for (let o = 0; o < this.parsedData.length; o++) {
        if (this.parsedData[o].name == "kunden") {
          let customerData = this.parsedData[o].data;

          this.collectAndSendCustomers(customerData);
        }
      }
    },

    collectAndSendCustomers(customers) {
      if (customers.length > 0) {
        let customer = customers.pop();

        let phoneNr = "";
        if (customer.Fonpriv != null && customer.Fonpriv != "") {
          phoneNr = customer.Fonpriv;
        }
        if (customer.Fongesch != null && customer.Fongesch != "") {
          phoneNr = customer.Fongesch;
        }

        if (
          customer.Fonpriv != null &&
          customer.Fonpriv != "" &&
          customer.Fongesch != null &&
          customer.Fongesch != ""
        ) {
          this.errorMsg.push({
            msg: "Kunde hatte 2 Nummern!",
            data: [customer.id_Kunden, customer.Fonpriv, customer.Fongesch],
          });
        }

        let salutation = 2;
        if (customer.Anrede && customer.Anrede.toLowerCase() == "herr") {
          salutation = 0;
        }
        if (customer.Anrede && customer.Anrede.toLowerCase() == "frau") {
          salutation = 1;
        }

        let postcode = isNaN(parseInt(customer.PLZ)) ? null : customer.PLZ;
        if (postcode && postcode.length > 5) {
          postcode = postcode.substring(0, 5);
        }

        let formData = {
          salutation: salutation,
          last_name: customer.Name == "." ? null : customer.Name,
          first_name: customer.Vorname == "." ? null : customer.Vorname,
          company: customer.Firma == "." ? null : customer.Firma,
          city: customer.Ort,
          country: "Deutschland",
          address1: customer.Strasse,
          postcode: postcode,
          mail: customer.Mail,
          phone: phoneNr,
          mobile: customer.Handy,
          send_notification_mail: false,
          vip: false,
          annotation: customer.Bemerkung,
        };

        axios
          .post(axios.defaults.baseURL + "customers/", formData)
          .then((resp) => {
            ++this.customersSent;
            this.oldToNewCustomerMapping[customer.id_Kunden] = resp.data.id;
            this.collectAndSendCustomers(customers);
          })
          .catch((e) => {
            console.log(e);
            this.errorMsg.push({
              msg: "Kunde konnte nicht gesendet werden!",
              data: [customer.id_Kunden],
            });
            this.collectAndSendCustomers(customers);
          });
      } else {
        ++this.state;
      }
    },

    collectAndSendVehiclesSetup() {
      for (let o = 0; o < this.parsedData.length; o++) {
        if (this.parsedData[o].name == "Kfz") {
          let vehicleData = this.parsedData[o].data;

          this.collectAndSendVehicles(vehicleData);
        }
      }
    },

    collectAndSendVehicles(vehicles) {
      if (vehicles.length > 0) {
        let vehicle = vehicles.pop();

        if (this.oldToNewCustomerMapping[vehicle.id_Kunden] == undefined) {
          this.errorMsg.push({
            msg: "Vehicle is missing its Customer!",
            data: [vehicle.id_Kfz, vehicle.id_Kunden],
          });

          this.collectAndSendVehicles(vehicles);
        } else {
          let plate = vehicle.Kennzeichen;
          if (plate.length > 11) {
            plate = plate.substring(0, 11);
          }
          if (!plate || plate.length == 0 || plate == " ") {
            plate = "--";
          }

          let model = vehicle.Marke;
          if (!model || model.length == 0 || model == " ") {
            model = "--";
          }

          let type = vehicle.Typ;
          if (!type || type.length == 0 || type == " ") {
            type = "--";
          }

          let formData = {
            plate: plate,
            Customer: this.oldToNewCustomerMapping[vehicle.id_Kunden],
            model: model,
            type: type,
          };

          axios
            .post(axios.defaults.baseURL + "vehicles/new/", formData)
            .then((resp) => {
              ++this.vehiclesSent;
              this.oldToNewVehicleMapping[vehicle.id_Kfz] = resp.data.id;
              this.collectAndSendVehicles(vehicles);
            })
            .catch((e) => {
              console.log(e);
              this.errorMsg.push({
                msg: "Kfz konnte nicht gesendet werden!",
                data: [vehicle.id_Kfz],
              });
              this.collectAndSendVehicles(vehicles);
            });
        }
      } else {
        ++this.state;
      }
    },

    collectAndSendTiresSetup() {
      this.oldToNewCustomerMapping = [];

      for (let o = 0; o < this.parsedData.length; o++) {
        if (this.parsedData[o].name == "Reifen") {
          let tireData = this.parsedData[o].data;

          this.collectAndSendTires(tireData);
        }
      }
    },

    collectAndSendTires(tires) {
      if (tires.length > 0) {
        let tire = tires.pop();

        if (this.oldToNewVehicleMapping[tire.id_Kfz] == undefined) {
          this.errorMsg.push({
            msg: "Tire is missing its Vehicle!",
            data: [tire.id_Kfz],
          });

          this.collectAndSendTires(tires);
        } else {
          let correctedDot;
          if (!tire.DotNr) correctedDot = "0000";
          else correctedDot = tire.DotNr;

          if (correctedDot.length > 4) {
            correctedDot = correctedDot.substring(0, 4);
          } else {
            while (correctedDot.length != 4) {
              correctedDot += "0";
            }
          }

          let formData = {
            Vehicle: this.oldToNewVehicleMapping[tire.id_Kfz],
            manufacturer: tire.Hersteller,
            model: tire.name,
            type: tire.Felge == 0 ? 2 : tire.Felge == 1 ? 1 : 0,
            value: tire.Wert,
            width: tire.Breite,
            radius: tire.Flanke,
            cross_section: tire.Zoll,
            season: tire.Saison == 1 ? 1 : tire.Saison == 0 ? 2 : 0,
            dot: correctedDot,
            profile_r: tire.ProfilH,
            profile_f: tire.ProfilV,
            annotation: tire.Bemerkung,
          };

          axios
            .post(axios.defaults.baseURL + "tires/simple/", formData)
            .then((resp) => {
              ++this.tiresSent;
              this.oldToNewTireMapping[tire.id_Reifen] = resp.data.id;
              this.collectAndSendTires(tires);
            })
            .catch((e) => {
              console.log(e);
              this.errorMsg.push({
                msg: "Reifen konnte nicht gesendet werden!",
                data: [tire.id_Reifen, tire.id_Kfz],
              });
              this.collectAndSendTires(tires);
            });
        }
      } else {
        ++this.state;
      }
    },

    collectAndSendStorageSetup() {
      this.oldToNewVehicleMapping = [];

      for (let o = 0; o < this.parsedData.length; o++) {
        if (this.parsedData[o].name == "Lager") {
          let storageData = this.parsedData[o].data;

          this.collectAndSendStorages(storageData);
        }
      }
    },

    collectAndSendStorages(storages) {
      let warehouses = [];

      //get main buildings
      for (let i = 0; i < storages.length; i++) {
        if (warehouses[storages[i].LagerNr] == undefined) {
          warehouses[storages[i].LagerNr] = {
            name: storages[i].LagerNr,
            maxRow: 0,
            rowHeight: 3,
            maxSpacePerRow: [],
            idsPerRow: [],
          };
        }
      }

      //get row number per building
      for (let i = 0; i < storages.length; i++) {
        if (parseInt(storages[i].ReiheNr) > warehouses[storages[i].LagerNr].maxRow) {
          warehouses[storages[i].LagerNr].maxRow = parseInt(storages[i].ReiheNr);
        }

        if (
          warehouses[storages[i].LagerNr].maxSpacePerRow[storages[i].ReiheNr] ==
            undefined ||
          warehouses[storages[i].LagerNr].maxSpacePerRow[storages[i].ReiheNr] <
            parseInt(storages[i].PlatzNr)
        ) {
          warehouses[storages[i].LagerNr].maxSpacePerRow[storages[i].ReiheNr] = parseInt(
            storages[i].PlatzNr
          );
        }
      }

      this.warehouses = [];

      for (let i in warehouses) {
        for (let j = 1; j < warehouses[i].maxSpacePerRow.length; j++) {
          if (!isNaN(parseInt(warehouses[i].maxSpacePerRow[j])))
            warehouses[i].maxSpacePerRow[j]++;
        }

        if (warehouses[i].name) this.warehouses.push(warehouses[i]);
      }

      console.log(this.warehouses);
      this.sendWarehouses(0);
    },

    sendWarehouses(index) {
      if (index == this.warehouses.length) {
        this.sendRows(0);
        return;
      }

      axios
        .post(axios.defaults.baseURL + "storages/simple/", {
          name: this.warehouses[index].name,
        })
        .then((resp) => {
          this.warehouses[index].id = resp.data.id;

          this.sendWarehouses(++index);
        });
    },

    sendRows(index, currRow = 1) {
      if (index == this.warehouses.length) {
        this.sendSpaces(0);
        return;
      }

      let wh = this.warehouses[index];
      if (currRow <= wh.maxRow) {
        axios
          .post(axios.defaults.baseURL + "rows/simple/", {
            Storage: this.warehouses[index].id,
            number: currRow,
            height: 3,
          })
          .then((resp) => {
            this.warehouses[index].idsPerRow[currRow] = resp.data.id;

            this.sendRows(index, ++currRow);
          });
      } else {
        this.sendRows(++index);
      }
    },

    sendSpaces(index, currRow = 1, currSpace = 1) {
      if (index == this.warehouses.length) {
        this.state++;
        return;
      }

      let wh = this.warehouses[index];

      if (currRow <= wh.maxRow) {
        if (currSpace <= wh.maxSpacePerRow[currRow]) {
          axios
            .post(axios.defaults.baseURL + "spaces/simple/", {
              Row: this.warehouses[index].idsPerRow[currRow],
              number: currSpace,
            })
            .then(() => {
              ++this.storagesSent;
              this.sendSpaces(index, currRow, ++currSpace);
            });
        } else {
          this.sendSpaces(index, ++currRow, 1);
        }
      } else {
        this.sendSpaces(++index, 1, 1);
      }
    },
    setupConnectTiresToStorage() {
      console.log(this.oldToNewTireMapping);
      let storageData;
      for (let o = 0; o < this.parsedData.length; o++) {
        if (this.parsedData[o].name == "Lager") {
          storageData = this.parsedData[o].data;
        }
      }

      let refinedStorageData = {};
      for (let i = 0; i < storageData.length; i++) {
        if (!refinedStorageData[storageData[i].LagerNr]) {
          refinedStorageData[storageData[i].LagerNr] = { rows: [] };
        }

        if (!refinedStorageData[storageData[i].LagerNr].rows[storageData[i].ReiheNr]) {
          refinedStorageData[storageData[i].LagerNr].rows[storageData[i].ReiheNr] = {
            spaces: [],
          };
        }

        if (
          !refinedStorageData[storageData[i].LagerNr].rows[storageData[i].ReiheNr].spaces[
            parseInt(storageData[i].PlatzNr) + 1
          ]
        ) {
          refinedStorageData[storageData[i].LagerNr].rows[storageData[i].ReiheNr].spaces[
            parseInt(storageData[i].PlatzNr) + 1
          ] = [];
        }

        if (!this.oldToNewTireMapping[storageData[i].id_Reifen]) {
          console.log("Reifen fehlt!", storageData[i].id_Reifen);
        } else {
          refinedStorageData[storageData[i].LagerNr].rows[storageData[i].ReiheNr].spaces[
            parseInt(storageData[i].PlatzNr) + 1
          ].push(this.oldToNewTireMapping[storageData[i].id_Reifen]);
        }
      }

      this.connectTiresToStorage(refinedStorageData);
    },
    connectTiresToStorage(refinedStorageData) {
      for (let i in refinedStorageData) {
        if (refinedStorageData[i].rows) {
          for (let j in refinedStorageData[i].rows) {
            if (refinedStorageData[i].rows[j]) {
              let row = refinedStorageData[i].rows[j];
              refinedStorageData[i].rows[j] = undefined;

              axios
                .get(
                  axios.defaults.baseURL +
                    "space/by/row/and/storage/?storage=" +
                    parseInt(i) +
                    "&row=" +
                    parseInt(j)
                )
                .then((resp) => {
                  let dbRowData = resp.data;

                  this.setupFinalTireConnect(row, dbRowData, refinedStorageData);
                })
                .catch(() => {
                  this.connectTiresToStorage(refinedStorageData);

                  this.errorMsg.push({
                    msg: "Reifen konnte nicht connected werden! (Reihe)",
                    data: ["Lager " + i, "Reihe " + j],
                  });
                });

              return;
            }
          }
        }
      }
    },
    setupFinalTireConnect(row, databaseData, refinedStorageData) {
      if (databaseData.length > 0) {
        let nextSpace = databaseData.shift();

        axios
          .patch(axios.defaults.baseURL + "spaces/patch/" + nextSpace.id, {
            id: nextSpace.id,
            tire_set: row.spaces[nextSpace.number],
          })
          .then(() => {
            this.setupFinalTireConnect(row, databaseData, refinedStorageData);
          })
          .catch(() => {
            this.setupFinalTireConnect(row, databaseData, refinedStorageData);

            this.errorMsg.push({
              msg: "Reifen konnte nicht connected werden!",
              data: [nextSpace.id, nextSpace.tire_set],
            });
          });
      } else {
        this.connectTiresToStorage(refinedStorageData);
      }
    },
  },
};
</script>
