<template>
  <div>
    <v-container>
      <v-row>
        <v-col :cols="cols" v-for="customer in customers" :key="customer.id">
          <v-card class="smallSpacingCard">
            <v-card-title class="pb-0"> {{ customer.company }}</v-card-title>
            <v-card-title class="py-0">
              {{ customer.first_name }}
            </v-card-title>
            <v-card-title class="pt-0"> {{ customer.last_name }}</v-card-title>

            <v-card-text
              >Fahrzeuge: {{ customer.numberOfVehicles }}
            </v-card-text>
            <v-card-text>E-Mail: {{ customer.mail }} </v-card-text>
            <v-card-text>Tel: {{ customer.phone }} </v-card-text>
            <v-card-text>Letzter Besuch: {{ customer.last_visit }} </v-card-text>
            <v-card-text>Adresse: {{ customer.city }} </v-card-text>
            <v-card-text> {{ customer.street }} </v-card-text>
            <v-card-text> {{ customer.postcode }} </v-card-text>

            <br />

            <v-card-actions>
              <v-btn block color="primary" @click="gotoCustomer()"
                ><v-icon class="white--text">mdi-pencil</v-icon></v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>

const axios = require("axios");

export default {
  name: "customer",

  components: {},

  data() {
    return {
      customers: [],
    };
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 6;
        case "lg":
          return 4;
        default:
          return 3;
      }
    },
  },

  mounted() {
    axios.get(axios.defaults.baseURL + "customers/")
    .then((resp) =>{
      this.customers = resp.data;
    })
  },

  methods: {
      gotoCustomer(){
          this.$router.push("/kunden/123/")
      }
  },
};
</script>
