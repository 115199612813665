var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"innerCard mb-16",attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.colLimit}},[_c('v-card',{staticClass:"ma-8 pa-8 mt-16",attrs:{"height":"fit-content","min-height":"80vh","flat":""}},[_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-spacer'),_c('v-col',[_c('h3',[_vm._v("Terminübersicht "+_vm._s(_vm.date))])]),_c('v-spacer'),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4"}},[_c('v-combobox',{staticClass:"hideInputBottomSpace mb-0",attrs:{"value":_vm.displayStages,"items":_vm.stages,"multiple":"","chips":"","close":"","label":"Bühnen"},on:{"input":_vm.switchStageDisplay},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1)]}}])})],1)],1)],1),_c('v-divider'),(_vm.appointments.length == 0)?_c('v-container',{staticClass:"ma-0 pa-0 mt-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-spacer'),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"6"}},[_vm._v(" Keine Termine vorhanden! ")]),_c('v-spacer')],1)],1):_c('div',[(_vm.displayedAppointments.length == 0)?_c('v-container',{staticClass:"ma-0 pa-0 mt-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-spacer'),_vm._l((_vm.displayedAppointments),function(app){return _c('v-col',{key:app.id,staticClass:"ma-0 pa-0",attrs:{"cols":"3"}},[_vm._v(" Keine Termine auf dieser Bühne! ")])}),_c('v-spacer')],2)],1):_c('v-container',{staticClass:"ma-0 pa-0 mt-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},_vm._l((_vm.displayedAppointments.slice(
                    0,
                    _vm.currentTimeIndex
                  )),function(app){return _c('v-col',{key:app.id,staticClass:"ma-0 pa-0",attrs:{"cols":_vm.numOfCards}},[_c('AppointmentDayCard',{attrs:{"opacity":"0.6","appointment":app,"icons":_vm.icons,"tireAppType":_vm.tireAppType},on:{"switchStorage":_vm.switchStorage,"print":_vm.printLabels}})],1)}),1),_c('v-row',{staticStyle:{"color":"var(--v-primary-base)","font-weight":"bolder","font-size":"x-large"}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.currentTime)+" ")])],1),_c('v-divider',{staticStyle:{"border-width":"1px","border-color":"var(--v-primary-base)"}}),_c('v-row',{staticClass:"ma-0 pa-0"},_vm._l((_vm.displayedAppointments.slice(
                    _vm.currentTimeIndex,
                    _vm.displayedAppointments.length
                  )),function(app){return _c('v-col',{key:app.id,staticClass:"ma-0 pa-0",attrs:{"cols":_vm.numOfCards}},[_c('AppointmentDayCard',{attrs:{"opacity":1,"appointment":app,"icons":_vm.icons,"tireAppType":_vm.tireAppType},on:{"switchStorage":_vm.switchStorage,"print":_vm.printLabels}})],1)}),1)],1)],1)],1)],1)],1)],1),_c('LabelPDF',{staticStyle:{"z-index":"-1","opacity":"0"},attrs:{"tire":_vm.tireData,"customerName":_vm.customerName,"customerID":_vm.customerID,"vehicle":_vm.vehicleData,"printNow":_vm.printLabelPDF}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }