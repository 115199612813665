<template>
  <div>
    <v-card class="pa-10" style="width: 50vw !important; height: 70vh; margin: 0 auto">
      <v-btn class="overlayClose rounded-xl" text icon @click="emitClose(undefined)">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-container fluid>
        <v-row>
          <v-spacer />
          <v-col cols="auto" class="py-0">
            <h2>Historie</h2>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-card flat style="width: 100%; height: 60vh" class="innerCard">
            <v-container fluid>
              <div v-for="r in records" :key="r.id">
                <v-row class="mt-4">
                  <v-col>
                    {{ annotationToString(r.annotation) }}
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <b v-if="r.record_type == 1">Eingelagert</b
                    ><b v-else>Ausgelagert</b> am
                    <b>{{ getReadableDateAndTimeString(r.date) }}</b>
                  </v-col>
                </v-row>
                <v-divider />
              </div>
            </v-container>
          </v-card>
        </v-row>
      </v-container>

      <br />
    </v-card>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "history",

  props: ["customerID"],

  data() {
    return {
      records: [],
    };
  },

  methods: {
    emitClose() {
      this.$emit("cancel");
    },
    getReadableDateAndTimeString(val) {
      let newDate = new Date(val);

      return (
        newDate.toLocaleString([], {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }) + " Uhr"
      );
    },
    annotationToString(val) {
      switch (val.type) {
        case "tire":
          return (
            "KFZ: " +
            val.plate +
            ", Reifensatz: " +
            val.dot +
            ", Hersteller: " +
            (val.manufacturer || "--") +
            " " +
            (val.model || "--")
          );
      }
    },
  },

  mounted() {
    axios
      .get(axios.defaults.baseURL + "storagerecords/by/customer/?id=" + this.customerID)
      .then((resp) => {
        this.records = resp.data;
      });
  },
};
</script>
