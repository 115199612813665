<template>
  <div id="login">
    <v-app>
      <v-container fill-height fluid height="90vh">
        <v-row align="center" justify="center">
          <v-col :cols="colLimit">
            <v-card flat class="pa-8">
              <v-container fluid class="ma-auto">
                <v-row md="auto" justify="center" no-gutters class="pa-0 ma-0">
                  <v-col class="pa-0 ma-0">
                    <v-card flat align="center">
                      <v-container fluid>
                        <v-row>
                          <v-spacer />
                          <v-col class="pa-0 ma-0">
                            <v-img
                              id="loginLogo"
                              src="../../assets/images/ReifenReiboldLogo.png"
                              class="px-2"
                            ></v-img>
                          </v-col>
                          <v-spacer />
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>

                <v-form ref="form" lazy-validation>
                  <v-row md="auto" justify="center" no-gutters>
                    <v-col>
                      <v-card class="pa-2" flat align="center">
                        <v-card-title class="px-0">Login</v-card-title>
                        <v-text-field
                          id="email"
                          :rules="emailRules"
                          label="E-Mail"
                          v-model="email"
                          required
                        ></v-text-field>
                        <v-text-field
                          id="password"
                          :rules="passwordRules"
                          :type="showEyeOpen ? 'password' : 'regular'"
                          label="Passwort"
                          v-model="password"
                          required
                          :append-icon="showEyeOpen ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="toggleShowPassword"
                        >
                        </v-text-field>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col :cols="buttonCols">
                      <v-card class="pa-2" flat>
                        <v-btn
                          @click="submit"
                          depressed
                          large
                          color="primary"
                          class="rounded-lg"
                          elevation="2"
                          dark
                          block
                          >Login</v-btn
                        >
                      </v-card>
                    </v-col>

                    <v-spacer v-if="buttonCols < 6" />

                    <v-col
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                      "
                      :cols="buttonCols == 12 ? buttonCols : 'auto'"
                    >
                      <v-card class="pa-2" flat width="inherit">
                        <v-btn depressed large class="outlinedButton" block>
                          <span style="color: var(--v-primary-base)"
                            >Passwort vergessen?</span
                          >
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "login",

  data() {
    return {
      isValid: true,
      email: "",
      emailRules: [
        (v) => (v && this.isValid) || "E-Mail oder Passwort falsch!",
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRules: [(v) => (v && this.isValid) || "E-Mail oder Passwort falsch!"],
      showEyeOpen: true,
      url: axios.defaults.baseURL + "recover/",
    };
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 6;
        case "lg":
          return 5;
        default:
          return 3;
      }
    },
    colLimit() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 8;
        case "md":
          return 6;
        case "lg":
          return 5;
        case "xl":
          return 3;
        default:
          return 8;
      }
    },

    buttonCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 5;
        case "lg":
          return 3;
        case "xl":
          return 3;
        default:
          return 8;
      }
    },
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    submit() {
      this.email = this.email.toLowerCase();
      const formData = {
        email: this.email,
        password: this.password,
      };
      this.$store.dispatch("loginUser", formData).catch((err) => {
        if (err.response.status == 401) {
          this.isValid = false;
          this.$refs.form.validate();
        }
      });
    },
    toggleShowPassword() {
      this.showEyeOpen = !this.showEyeOpen;
    },
  },
  mounted() {
    window.addEventListener("keypress", (e) => {
      if (e.key == "Enter") this.submit();
    });
  },
};
</script>
