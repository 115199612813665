<template>
  <div>
    <v-container fill-height fluid class="innerCard mb-16">
      <v-row align="center" justify="center">
        <v-col :cols="colLimit">
          <v-card height="fit-content" class="ma-8 pa-8 mt-16" flat>
            <v-sheet>
              <v-toolbar flat>
                <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                  Aktueller Monat
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-menu
                  class="py-0"
                  ref="dateSelector"
                  v-model="showMonthPicker"
                  type="month"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <h3 v-if="focus" v-on="on" v-bind="attrs">
                      {{ months[parseInt(focus.split("-")[1] - 1)] }}
                    </h3>
                    <h3 v-else v-on="on" v-bind="attrs">
                      {{ months[new Date().getMonth()] }}
                    </h3>
                  </template>
                  <v-date-picker
                    type="month"
                    locale="de-DE"
                    v-model="datePickerData"
                  ></v-date-picker>
                </v-menu>

                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>

                <h4 v-if="focus">
                  {{ focus.split("-")[0] }}
                </h4>
                <h4 v-else>
                  {{ new Date().getFullYear() }}
                </h4>

                <v-spacer></v-spacer>

                <v-spacer></v-spacer>
                <v-spacer></v-spacer>

                <v-btn
                  class="rounded-lg outlinedButton"
                  elevation="0"
                  @click="gotoAppointments()"
                  ><v-icon>mdi-calendar-month</v-icon></v-btn
                >
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                ref="calendar"
                v-model="focus"
                :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                :type="type"
                :events="events"
                :event-overlap-mode="mode"
                :event-overlap-threshold="30"
                :event-color="getEventColor"
                @change="getEvents"
                @click:day="clickOnDay"
                @click:date="clickOnDay"
              >
                <template v-slot:day="{ date }">
                  <v-row height="30px" v-if="tracked[date]">
                    <template>
                      <v-sheet
                        v-for="(percent, i) in tracked[date][1]"
                        :key="i"
                        :title="category[i]"
                        :color="colors[i]"
                        :width="`${percent}%`"
                        height="30px"
                        tile
                      ></v-sheet>
                    </template>
                  </v-row>

                  <v-row height="30px" v-if="tracked[date]">
                    <template>
                      <v-sheet
                        v-for="(percent, i) in tracked[date][2]"
                        :key="i"
                        :title="category[i]"
                        :color="colors[i]"
                        :width="`${percent}%`"
                        height="30px"
                        tile
                      ></v-sheet>
                    </template>
                  </v-row>
                </template>
              </v-calendar>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    monthSelectorData: undefined,
    focus: undefined,
    months: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],

    type: "month",
    types: ["month", "week", "day", "4day"],
    mode: "stack",
    value: "",
    events: [],

    tracked: {
      // "2022-08-09": { 1: [23, 45, 10], 2: [3, 7, 8] },
      // "2022-08-08": { 1: [23, 45, 10], 2: [3, 7, 8] },
      // "2022-08-07": { 1: [23, 45, 10], 2: [3, 7, 8] },
    },
    colors: ["#1867c0", "#fb8c00", "#000000"],
    category: ["Development", "Meetings", "Slacking"],

    showMonthPicker: false,
    datePickerData: undefined,
  }),

  watch: {
    datePickerData(val) {
      this.focus = val + "-01";
    },
  },

  computed: {
    colLimit() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 10;
        case "md":
          return 8;
        case "lg":
          return 9;
        case "xl":
          return 6;
        default:
          return 8;
      }
    },
  },

  methods: {
    clickOnDay(event) {
      this.$router.push("/termine/" + event.date);
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    getEvents() {
      // getEvents({start, end}) {
      // const events = [];
      // const min = new Date(`${start.date}T00:00:00`);
      // const max = new Date(`${end.date}T23:59:59`);
      // const days = (max.getTime() - min.getTime()) / 86400000;
      // const eventCount = this.rnd(days, days + 20);
      // for (let i = 0; i < eventCount; i++) {
      //   const allDay = this.rnd(0, 3) === 0;
      //   const firstTimestamp = this.rnd(min.getTime(), max.getTime());
      //   const first = new Date(firstTimestamp - (firstTimestamp % 900000));
      //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
      //   const second = new Date(first.getTime() + secondTimestamp);
      //   events.push({
      //     name: this.names[this.rnd(0, this.names.length - 1)],
      //     start: first,
      //     end: second,
      //     color: this.colors[this.rnd(0, this.colors.length - 1)],
      //     timed: !allDay,
      //   });
      // }
      // this.events = events;
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    gotoAppointments() {
      this.$router.push("termine");
    },
  },
};
</script>
