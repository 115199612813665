<template>
  <div>
    <v-app-bar fixed app color="toolbar" height="55px">
      <v-container style="height: 44px" fluid class="pa-0 ma-0">
        <v-row class="pa-0 ma-0">
          <v-col cols="auto" class="pa-0 ma-0" style="height: 44px; display: flex">
            <button @click="gotoDashboard()" tag="button" class="headerLink">
              <v-img
                src="../../assets/images/ReifenReiboldLogo.png"
                contain
                height="40px"
                width="auto"
                class=""
                id="headerImg"
                style="max-width: 300px"
              ></v-img>
            </button>
          </v-col>
          <v-spacer />
          <v-col
            cols="auto"
            class="pa-0 ma-0"
            style="height: 44px"
            v-if="!isMobile"
          >
            <v-btn
              style="
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                pointer-events: none;
              "
              text
              color="primary"
              class="hideButtonEffect"
            >
              <p class="my-3 text-no-wrap text-truncate">Hallo, {{ name }}</p>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="pa-0 ma-0" style="height: 44px">
            <v-btn
              @click="logOut"
              text
              color="primary"
              class="hideButtonEffect"
            >
              <v-icon class="pr-3" id="logoutIcon" style="font-size: xx-large"
                >mdi-logout</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-card>
      <v-container>
        <v-row>
          <v-spacer />
          <v-col cols="12">
            <FuhrparkDetailVue :companies="this.vehicles" />
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import FuhrparkDetailVue from "@/components/Customer/Fuhrpark/FuhrparkDetail.vue";
// import SearchBarVue from "@/components/Header/SearchBar.vue";

export default {
  name: "Fuhrpark",
  data() {
    return {
      vehicles: [],

      name: localStorage.getItem("first_name"),
    };
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return false;
      }
    },
  },
  components: {
    // SearchBarVue,
    FuhrparkDetailVue,
  },
  methods: {
    logOut() {
      this.$store.dispatch("logoutUser");
    },
    gotoDashboard() {
      this.$router.push("/dashboard");
    },
  },
};
</script>
