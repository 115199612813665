import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/index'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import interceptorSetup from './router/interceptors'
import 'es6-promise/auto';

// Require the polyfill before requiring any other modules.
require('intersection-observer');

const mode = "DEV"
// const mode = "PROD"
// const mode = "STAGING"

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

// axios.defaults.baseURL = "http://localhost:8000/api/v1/" //DEV
// axios.defaults.baseURL = "https://rrdb.roba.one/api/v1/" //STAGING
axios.defaults.baseURL = "https://stagedb.reifen-reibold.de/api/v1/" //PROD

// axios.defaults.baseURL = "http://192.168.10.132:8000/api/v1/"

//initialising axios interceptors for refresh token
interceptorSetup()

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  data: () => ({
    alignments: [
      'start',
      'center',
      'end',
    ],
  }),
  render: h => h(App)
}).$mount('#app')


// add_header Access-Control-Allow-Origin *;
// add_header 'Access-Control-Allow-Headers' 'DNT,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range';