<template>
  <div id="searchBar">
    <v-container class="px-0">
      <v-row>
        <v-col class="mx-0">
          <v-combobox
            class="searchBar"
            color="tertiary"
            label="Suche"
            single-line
            clearable
            flat
            filled
            prepend-inner-icon="mdi-magnify"
            @update:search-input="fetchModels"
            v-model="searchQueue"
            :items="searchResults"
            ref="searchBar"
          >
            <template v-slot:no-data>
              <v-container @click="addNewCustomer">
                <v-row class="pa-0 ma-0">
                  <v-col cols="auto" class="pa-0 ma-0">
                    <v-icon class="pa-0 ma-0">mdi-plus</v-icon>
                  </v-col>
                  <v-col class="pa-0 ma-0 pl-1">
                    <h3 style="width: 100%">Neuen Kunden Anlegen</h3>
                  </v-col>
                </v-row>
              </v-container>
            </template>

            <template v-slot:prepend-item v-if="searchResults.length != 0">
              <v-container @click="addNewCustomer">
                <v-row class="pa-0 ma-0">
                  <v-col cols="auto" class="pa-0 ma-0">
                    <v-icon class="pa-0 ma-0">mdi-plus</v-icon>
                  </v-col>
                  <v-col class="pa-0 ma-0 pl-1">
                    <h3 style="width: 100%">Neuen Kunden Anlegen</h3>
                  </v-col>
                </v-row>
              </v-container>
              <hr />
            </template>
            <template v-slot:item="{ item }">
              <div
                style="display: flex; flex-direction: column; width: 100%"
                class="px-3"
                @click="gotoCustomer(item, $event)"
              >
                <h3
                  @click="gotoCustomer(item, $event)"
                  style="width: 100%"
                  :class="{ markOnHover: !disableCustomerClick }"
                >
                  {{
                    (item.customer && item.customer.company
                      ? item.customer.company
                      : "") +
                    " " +
                    (item.customer ? item.customer.firstName : "") +
                    " " +
                    (item.customer ? item.customer.lastName : "")
                  }}
                </h3>

                <v-container
                  @click="addNewVehicle(item, $event)"
                  class="pa-0 ma-0 pl-2 markOnHover"
                >
                  <v-row class="pa-0 ma-0">
                    <v-col cols="auto" class="pa-0 ma-0">
                      <v-icon class="pa-0 ma-0">mdi-plus</v-icon>
                    </v-col>
                    <v-col class="pa-0 ma-0 pl-1">
                      <p
                        style="
                          font-size: large;
                          color: #4e4c4c;
                          font-weight: bold;
                          display: inline;
                        "
                      >
                        Neues Fahrzeug
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
                <div
                  class="pl-2 markOnHover"
                  v-for="v in item.vehicles"
                  :key="v.id"
                  @click="gotoVehicle(item, v.id, $event)"
                  style="width: 100%"
                  :style="{ display: v.deleted ? 'none' : 'block' }"
                >
                  <p
                    style="
                      font-size: large;
                      color: #4e4c4c;
                      font-weight: bold;
                      display: inline;
                    "
                  >
                    {{ v.plate }}
                  </p>
                  <p style="color: #4e4c4c; display: inline">
                    {{ v.model + " " + v.type }}
                  </p>
                </div>
              </div>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
    </v-container>

    <div class="fullScreenOverlay" v-if="customerOverlayVisible">
      <div class="overlayBackground"></div>
      <NewCustomer
        class="overlayDisplay"
        @hide="customerOverlayVisible = false"
        @created="newCustomerCreated"
      />
    </div>
  </div>
</template>

<script>
const axios = require("axios");

import NewCustomer from "../Customer/NewCustomer.vue";

export default {
  name: "searchBar",

  props: ["disableCustomerClick"],

  data() {
    return {
      search: null,
      searchQueue: "",
      searchResults: [],

      customerOverlayVisible: false,
    };
  },

  components: {
    NewCustomer,
  },

  methods: {
    newCustomerCreated(data) {
      this.$emit("customerCreated", data);
      this.customerOverlayVisible = false;
    },
    addNewVehicle(item, event) {
      this.$emit("vehicleClick", {
        item: item,
        searchQueue: this.searchQueue,
        vehicleID: 0,
      });

      this.searchQueue = "";
      this.searchResults = [];
      this.$refs.searchBar.blur();

      event.stopPropagation();
    },
    addNewCustomer() {
      this.searchQueue = "";
      this.searchResults = [];
      this.$refs.searchBar.blur();
      this.customerOverlayVisible = true;
    },
    gotoVehicle(item, vehicleID, event) {
      this.$emit("vehicleClick", {
        item: item,
        searchQueue: this.searchQueue,
        vehicleID: vehicleID,
      });

      this.searchQueue = "";
      this.searchResults = [];
      this.$refs.searchBar.blur();

      event.stopPropagation();
    },
    gotoCustomer(item, event) {
      this.$emit("customerClick", {
        item: item,
        searchQueue: this.searchQueue,
      });

      if (!this.disableCustomerClick) {
        this.searchQueue = "";
        this.searchResults = [];
        this.$refs.searchBar.blur();
      } else {
        event.stopPropagation();
      }
    },
    onClearClicked() {
      // window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    },
    fetchModels(val) {
      this.searchQueue = val;

      this.searchResults = [];

      if (this.searchQueue && this.searchQueue.length >= 2) {
        axios //authenticated get request
          .get(axios.defaults.baseURL + "general/search/?searchQ=" + this.searchQueue)
          .then((response) => {
            let helperObject = {};

            for (let i = 0; i < response.data.customers.length; i++) {
              let newCustomer = {
                customer: {
                  id: response.data.customers[i].id,
                  firstName: response.data.customers[i].first_name || "",
                  lastName: response.data.customers[i].last_name || "",
                  company: response.data.customers[i].company || "",
                },
                vehicles: [],
              };

              for (let j = 0; j < response.data.customers[i].vehicle_set.length; j++) {
                newCustomer.vehicles.push({
                  id: response.data.customers[i].vehicle_set[j].id,
                  plate: response.data.customers[i].vehicle_set[j].plate,
                  model: response.data.customers[i].vehicle_set[j].model,
                  type: response.data.customers[i].vehicle_set[j].type || "",
                  need_new_tire: response.data.customers[i].vehicle_set[j].need_new_tire,
                  has_no_rim: response.data.customers[i].vehicle_set[j].has_no_rim,
                  deleted: response.data.customers[i].vehicle_set[j].deleted,
                });
              }

              helperObject[newCustomer.customer.id] = newCustomer;
            }

            for (let i = 0; i < response.data.vehicles.length; i++) {
              let newCustomer = {
                customer: {
                  id: response.data.vehicles[i].Customer.id,
                  firstName: response.data.vehicles[i].Customer.first_name || "",
                  lastName: response.data.vehicles[i].Customer.last_name || "",
                  company: response.data.vehicles[i].Customer.company || "",
                },
                vehicles: [
                  {
                    id: response.data.vehicles[i].id,
                    plate: response.data.vehicles[i].plate,
                    model: response.data.vehicles[i].model,
                    type: response.data.vehicles[i].type || "",
                    need_new_tire: response.data.vehicles[i].need_new_tire,
                    has_no_rim: response.data.vehicles[i].has_no_rim,
                    deleted: response.data.vehicles[i].deleted,
                  },
                ],
              };

              //make sure every customer is in the search results
              //only once and all his found vehicles are also sorted correctly
              if (helperObject[newCustomer.customer.id] != undefined) {
                for (let k = 0; k < newCustomer.vehicles.length; k++) {
                  let found = false;
                  for (
                    let l = 0;
                    l < helperObject[newCustomer.customer.id].vehicles.length;
                    l++
                  ) {
                    if (
                      newCustomer.vehicles[k].id ==
                      helperObject[newCustomer.customer.id].vehicles[l].id
                    ) {
                      found = true;
                      break;
                    }
                  }

                  if (!found) {
                    helperObject[newCustomer.customer.id].vehicles.push(
                      newCustomer.vehicles[k]
                    );
                  }
                }
              } else {
                helperObject[newCustomer.customer.id] = newCustomer;
              }
            }

            for (let i in helperObject) {
              this.searchResults.push(helperObject[i]);
            }

            this.$forceUpdate();
          })
          .catch((error) => console.log(error));
      }
    },
  },
};
</script>
