<template>
  <v-card
    class="ma-2 pa-0"
    :style="{ opacity: opacity }"
    style="min-height: 230px"
    @click="gotoVehicle"
  >
    <v-card-title>
      <v-chip>{{ appointment.Stage.name }}</v-chip>

      <v-spacer />

      <b>{{ formattedTime(appointment.time) }} </b>
      <v-icon
        v-if="appointment.Vehicle.Customer && appointment.Vehicle.Customer.vip"
        class="pl-2"
        color="secondary"
        >mdi-star</v-icon
      >
      <v-spacer />
      <span>{{ appointment.duration }} Min</span>
    </v-card-title>
    <v-divider />
    <v-card-text class="pa-1">
      <v-container fluid class="pa-0 ma-0" fill-height>
        <v-row class="pa-0 ma-0">
          <v-col cols="9" class="pa-0 ma-0">
            <!-- <div style="display: inline"> -->
            <h3>{{ appointment.Vehicle.plate }}</h3>
            <!-- <p v-if="appointment.Vehicle.model">
          , {{ appointment.Vehicle.model + " " + appointment.Vehicle.type }}
        </p> -->
            <!-- </div> -->

            <div
              v-if="
                appointment.Vehicle.tire_set && appointment.Vehicle.tire_set.length > 0
              "
            >
              <div v-for="tire in appointment.Vehicle.tire_set" :key="tire.id">
                <h3 v-if="tire.in_storage">{{ tire.storage_number }}</h3>
              </div>
            </div>
            <div v-else>
              <h3>Nicht eingelagert</h3>
            </div>

            <span
              v-if="appointment.Vehicle.Customer"
              style="
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 90%;
                display: block;
                overflow: hidden;
              "
              >{{
                (appointment.Vehicle.Customer.first_name || "") +
                " " +
                (appointment.Vehicle.Customer.last_name || "")
              }}</span
            >
            <span
              style="
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 90%;
                display: block;
                overflow: hidden;
              "
              v-else-if="
                appointment.Vehicle.customer_first_name ||
                appointment.Vehicle.customer_last_name
              "
              >{{
                (appointment.Vehicle.customer_first_name || " ") +
                " " +
                (appointment.Vehicle.customer_last_name || " ")
              }}</span
            >
            <span v-else>-Kein Name-</span>
          </v-col>
          <v-col cols="3" class="px-0" style="display: flex; align-items: center">
            <v-btn
              @click="switchStorage"
              color="primary"
              style="min-width: unset"
              class="px-2"
              v-if="isTireAppointment && appointment.state_tire == 0"
              ><v-img :src="require('../../assets/icons/switchStorage.svg')"></v-img
            ></v-btn>
            <v-btn
              @click="printLabels"
              color="primary"
              style="min-width: unset"
              class="px-2"
              v-else-if="isTireAppointment && appointment.state_tire == 1"
              ><v-img :src="require('../../assets/icons/labelPDF.svg')"></v-img
            ></v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="auto" v-for="type in appointment.SubType" :key="type.id">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  v-html="icons[type.id]"
                  class="svgIconSizer"
                  :class="{
                    svgFileIconsEnabledSecondary: !appointment.Vehicle.Customer,
                    svgFileIconsEnabled: appointment.Vehicle.Customer,
                  }"
                ></div>
              </template>
              <span>{{ type.name }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "appDayCard",

  props: ["opacity", "appointment", "icons", "tireAppType"],

  data() {
    return {};
  },

  computed: {
    isTireAppointment() {
      for (let i = 0; i < this.appointment.SubType.length; i++) {
        if (this.appointment.SubType[i].Type == this.tireAppType) {
          if (
            this.appointment.Vehicle &&
            this.appointment.Vehicle.tire_set &&
            this.appointment.Vehicle.tire_set.length == 2
          ) {
            if (
              (this.appointment.Vehicle.tire_set[0].in_storage &&
                !this.appointment.Vehicle.tire_set[1].in_storage) ||
              (this.appointment.Vehicle.tire_set[1].in_storage &&
                !this.appointment.Vehicle.tire_set[0].in_storage)
            ) {
              return true;
            }
          }
        }
      }

      return false;
    },
  },

  methods: {
    gotoVehicle() {
      if (this.appointment.Vehicle.Customer) {
        this.$router.push(
          "/fahrzeug/" +
            this.appointment.Vehicle.Customer.id +
            "/" +
            this.appointment.Vehicle.id
        );
      }
    },
    formattedTime(timeString) {
      let stringToArray = timeString.split(":");

      return stringToArray[0] + ":" + stringToArray[1] + " Uhr";
    },
    switchStorage(event) {
      this.$emit("switchStorage", this.appointment);
      event.stopPropagation();
    },
    printLabels(event) {
      this.$emit("print", this.appointment);
      event.stopPropagation();
    },
  },

  mounted() {},
};
</script>
