<template>
    <div>
    <AppointmentBookingHeader />
        <AppointmentLogin @login="setUserData" v-if="showLogin" />
        <AppointmentBookingCalendar @booked="showCalendar = false" :userData="user" v-else-if="showCalendar"/>
        <AppointmentBooked v-else />
    </div>
</template>

<script>
// import axios from 'axios';
import AppointmentLogin from '@/components/Appointments/AppointmentLogin.vue';
import AppointmentBookingCalendar from '@/components/Appointments/AppointmentBookingCalendar.vue';
import AppointmentBooked from '@/components/Appointments/AppointmentBooked.vue';
import AppointmentBookingHeader from '@/components/Header/AppointmentBookingHeader.vue';

export default {
    data() {
        return {
            showLogin: true,
            showCalendar: false,
            user: {email: undefined, plate: undefined},
        }
    },
    components: {
        AppointmentLogin,
        AppointmentBookingCalendar,
        AppointmentBooked,
        AppointmentBookingHeader,
    },
    methods: {
        setUserData(data) {
            this.user.email = data.email;
            this.user.plate = data.plate.toUpperCase();
            this.showLogin = false;
            this.showCalendar = true;
        },
    }
}
</script>