<template>
  <div
    id="newCustomer"
    style="width: fit-content; height: fit-content; display: flex; align-items: center"
  >
    <v-card
      class="pa-10"
      style="height: fit-content; margin: 0 auto; max-height: 80vh; overflow-y: scroll"
      :style="{ width: cardWidth }"
    >
      <v-btn class="overlayClose rounded-xl" flat icon @click="closeOverlay">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-container fluid>
        <v-row>
          <v-col>
            <h2 class="text-center">Neuer Kunde</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="colWidth" class="py-0"><h3>Persönliche Daten</h3></v-col>
        </v-row>

        <v-row>
          <v-col :cols="colWidth" class="py-0">
            <v-text-field label="Firma" v-model="company"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="colWidth / 2" class="py-0">
            <v-select :items="salutations" label="Anrede" v-model="salutation"></v-select>
          </v-col>
          <v-col :cols="colWidth / 2" class="py-0">
            <v-text-field label="Vorname" v-model="firstName"></v-text-field>
          </v-col>
          <v-col :cols="colWidth / 2" class="py-0">
            <v-text-field label="Name" v-model="lastName"></v-text-field>
          </v-col>
          <v-col
            :cols="colWidth / 2"
            class="py-0"
            style="display: flex; align-items: center"
          >
            <v-menu
              class="py-0"
              ref="birthdayMenu"
              v-model="birthdayMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" class="pa-0 pr-3" style="width: 30px; height: 30px">
                  mdi-calendar
                </v-icon>
                <v-text-field
                  :value="getReadableDate(birthday)"
                  label="Geburtstag"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="birthday"
                :active-picker.sync="activePicker"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
                min="1920-01-01"
                @change="saveBirthday"
                locale="de-DE"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="colWidth" class="py-0"><h3>Adressdaten</h3></v-col>
        </v-row>
        <v-row>
          <v-col :cols="colWidth / 2" class="py-0">
            <v-text-field label="Straße" v-model="address1"></v-text-field>
          </v-col>
          <v-col :cols="colWidth / 2" class="py-0">
            <v-text-field label="Adresszusatz" v-model="address2"></v-text-field>
          </v-col>
          <v-col :cols="colWidth / 2" class="py-0">
            <v-text-field
              label="Postleitzahl"
              v-model="postcode"
              :rules="numericRules"
            ></v-text-field>
          </v-col>

          <v-col :cols="colWidth / 2" class="py-0">
            <v-text-field label="Ort" v-model="city"></v-text-field>
          </v-col>
          <v-col :cols="colWidth / 2" class="py-0">
            <v-text-field label="Land" v-model="country"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="colWidth" class="py-0"><h3>Kontaktdaten</h3> </v-col></v-row
        >
        <v-row>
          <v-col :cols="colWidth" class="py-0">
            <v-text-field label="E-Mail" v-model="mail"></v-text-field>
          </v-col>
          <v-col :cols="colWidth" class="py-0">
            <v-text-field label="Telefon" v-model="phone"></v-text-field>
          </v-col>
          <v-col :cols="colWidth" class="py-0">
            <v-text-field label="Mobil" v-model="mobile"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            :cols="colWidth / 2"
            class="py-0"
            style="display: flex; align-items: center"
          >
            <v-switch
              class="my-1"
              v-model="sendNotification"
              inset
              label="Erinnerungs E-Mail?"
            ></v-switch>
          </v-col>
          <v-col
            :cols="colWidth / 2"
            class="py-0"
            style="display: flex; align-items: center"
          >
            <v-switch class="my-1" v-model="isVIP" inset label="VIP"></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-spacer />
          <v-col :cols="colWidth / 2" class="py-0">
            <v-btn color="primary" block @click="sendCustomerData">Speichern</v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { getReadableDate } from "../../js/helperFunctions.js";

const axios = require("axios");

export default {
  name: "newCustomer",

  data() {
    return {
      salutations: ["Herr", "Frau", "Divers"],

      salutation: undefined,
      title: "",
      lastName: undefined,
      firstName: undefined,
      birthday: undefined,
      company: undefined,
      city: undefined,
      country: "Germany",
      address1: undefined,
      address2: undefined,
      postcode: undefined,
      mail: undefined,
      phone: undefined,
      mobile: undefined,
      sendNotification: false,
      isVIP: false,

      showBirthdayPicker: false,
      activePicker: null,
      birthdayMenu: false,

      numericRules: [(v) => !v || !isNaN(parseInt(v)) || "Nummer!"],
    };
  },

  computed: {
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "80vw !important";
        case "sm":
          return "80vw !important";
        case "md":
          return "65vw !important";
        case "lg":
          return "60vw !important";
        case "xl":
          return "40vw !important";
        default:
          return "40vw !important";
      }
    },
    colWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 6;
        case "lg":
          return 5;
        case "xl":
          return 4;
        default:
          return 4;
      }
    },
  },

  watch: {
    birthdayMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  methods: {
    getReadableDate: getReadableDate,
    closeOverlay() {
      this.$emit("hide", true);
    },
    sendCustomerData() {
      let formData = {
        salutation:
          this.salutations.indexOf(this.salutation) >= 0
            ? this.salutations.indexOf(this.salutation)
            : null,
        title: this.title,
        last_name: this.lastName,
        first_name: this.firstName,
        birthday: this.birthday,
        company: this.company,
        city: this.city,
        country: this.country,
        address1: this.address1,
        address2: this.address2,
        postcode: this.postcode,
        mail: this.mail,
        phone: this.phone,
        mobile: this.mobile,
        send_notification_mail: this.sendNotification,
        vip: this.isVIP,
      };

      axios
        .post(axios.defaults.baseURL + "customers/", formData)
        .then((resp) => {
          this.$emit("created", resp.data);
        })
        .catch((e) => console.log(e));
    },
    saveBirthday(date) {
      this.$refs.birthdayMenu.save(date);
    },
  },
};
</script>
