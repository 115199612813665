var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.vehicles,"search":_vm.query,"items-per-page":20,"id":"fuhrpark-table","footer-props":{ itemsPerPageOptions: _vm.pageSizes }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",staticStyle:{"width":"50%"},attrs:{"label":"Suche","outlined":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})]},proxy:true},{key:"item.tire_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"flex-grow":"0"},attrs:{"width":"32px","src":_vm.getFelge(item.tire_type)}},'v-img',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(item.tire_type == 0 ? "Alu" : item.tire_type == 1 ? "Stahl" : item.tire_type == 2 ? "Keine" : "")+" ")])],1)]}},{key:"item.tire_season",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticStyle:{"flex-grow":"0"},attrs:{"width":"32px","src":_vm.getSeason(item.tire_season)}},'v-img',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(item.tire_season == 0 ? "Allwetter" : item.tire_season == 1 ? "Sommer" : item.tire_season == 2 ? "Winter" : "")+" ")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }