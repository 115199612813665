<template>
  <div>
    <v-app>
      <v-container fluid fill-height height="90vh">
        <v-row align="center" justify="center">
          <v-col cols="10">
            <v-card class="pa-8" style="min-height: 70vh">
              <v-card-title
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  white-space: break-spaces;
                "
              >
                <b>Zeitpläne für Kundentermine</b>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      color="primary"
                      @click="showMailWarning = true"
                    >
                      <v-icon color="white">mdi-mail</v-icon>
                    </v-btn>
                  </template>
                  Saisonale Kundenmail senden
                </v-tooltip>
              </v-card-title>
              <v-container fluid class="ml-1 mr-1 pr-5">
                <v-row>
                  <v-col :cols="cardCols" v-for="a in appointments" :key="a.id">
                    <AppointmentSlot @refresh="fetchData" :appointmentData="a" />
                  </v-col>
                  <v-col :cols="cardCols">
                    <AppointmentSlot />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>

    <div class="fullScreenOverlay" v-if="showMailWarning">
      <div class="overlayBackground"></div>
      <WarnBanner
        class="overlayDisplay"
        @cancel="showMailWarning = false"
        @approve="sendInvitation"
        :action="'versenden'"
        :text="'eine Rundmail'"
        :buttonTextApprove="'Ja, Mails senden.'"
        :additional="'Dies sendet eine automatische E-Mail an alle Kunden, die im System sind und dem E-Mail-Empfang für die Online-Terminbuchung zugestimmt haben.'"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AppointmentSlot from "@/components/Appointments/AppointmentSlot.vue";
import WarnBanner from "@/components/Misc/WarnBanner.vue";

export default {
  data() {
    return {
      appointments: [],
      showMailWarning: false,
    };
  },
  components: {
    AppointmentSlot,
    WarnBanner,
  },
  computed: {
    cardCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        default:
          return 4;
      }
    },
  },
  methods: {
    fetchData() {
      axios
        .get(axios.defaults.baseURL + "appointments/schedules/")
        .then((resp) => {
          // console.log(resp.data);
          this.appointments = resp.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    sendInvitation() {
      axios
        .get(axios.defaults.baseURL + "appointments/invite/")
        .then(() => {
          // console.log(resp.data);
          this.showMailWarning = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
