<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="auto"
        v-for="(space, index) in rowSpaces"
        :key="space.id"
        class="px-0 pr-1 mt-3"
        :class="index % 5 == 0 ? 'pl-4' : ''"
        style="display: flex; flex-direction: column-reverse"
      >
        <StoragePlace :rowData="rowData" :placeData="space" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import StoragePlace from "./StoragePlace.vue";

export default {
  props: ["rowData", "storageName"],
  data() {
    return {
      rowSpaces: [],
      occupiedSpaces: 0,
      totalSpaces: 0,
    };
  },
  components: {
    StoragePlace,
  },
  methods: {
    fetchData() {
      axios
        .get(
          axios.defaults.baseURL +
            "space/by/row/and/storage/count/?row=" +
            this.rowData.number +
            "&storage=" +
            this.storageName
        )
        .then((resp) => {
          this.rowSpaces = resp.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    calcSpaces() {
      for (let i = 0; i < this.rowData.length; i++) {
        this.totalSpaces += this.rowData.height;
        this.occupiedSpaces += this.rowData.space_filled;
      }
    },
  },
  mounted() {
    this.fetchData();
    this.calcSpaces();
  },
};
</script>
