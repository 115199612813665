<template>
  <div>
    <v-card class="px-16 py-8">
      <v-container>
        <v-row>
          <v-col class="text-center">
            <h3>Bist du dir sicher, dass du {{ text }} {{ action }} willst?</h3>
            <p v-if="additional">
              {{ additional }}
            </p>
            <p v-if="!hideWarning">Diese Aktion kann nicht rückgängig gemacht werden!</p>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer />
          <v-col>
            <v-btn :color="acceptColors ? 'success' : 'primary'" @click="emitApprove">
              {{ buttonTextApprove || "Ja, Löschen!" }}
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col>
            <v-btn color="error" @click="emitCancel"> Nein, Zurück! </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "warning",

  props: ["text", "action", "hideWarning", "buttonTextApprove", "additional", "acceptColors"],

  methods: {
    emitApprove() {
      this.$emit("approve");
    },
    emitCancel() {
      this.$emit("cancel");
    },
  },
};
</script>
