import Vue from 'vue';
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
//import mdiVue from 'mdi-vue'
import * as mdijs from '@mdi/js'

Vue.use(Vuetify);
//Vue.use(mdiVue, {icons: mdijs}) 

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
        light: {
          primary: '#3A5676',
          secondary:'#6d88aa',
          background: '#F4F4F4',
          toolbar: '#FFF',
          detail: '#D9D9D9',
          accent: '#6d88aa'
      },
    },
  },

});

export default vuetify