<template>
  <div>
    <div id="element-to-print-label" ref="element-to-print-label" style="display: block">
      <!-- tire 1 -->
      <div class="html2pdf__page-break"></div>
      <div class="document" v-if="tire && customerName && vehicle">
        <h3>{{ customerName }}</h3>
        <table>
          <tbody>
            <tr>
              <td>
                <h2>{{ vehicle.plate }}</h2>
                <h3 v-if="vehicle.model && vehicle.type">
                  {{ vehicle.model }}
                  <br />
                  {{ vehicle.type }}
                </h3>
                <h3>{{ tire.tire_storage }}</h3>
              </td>
              <td>
                <QrcodeVue
                  style="display: flex; justify-content: center"
                  class="qrcode"
                  :value="qrValue"
                  level="M"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- tire 2 -->
      <div class="html2pdf__page-break"></div>
      <div class="document" v-if="tire && customerName && vehicle">
        <h3>{{ customerName }}</h3>
        <table>
          <tbody>
            <tr>
              <td>
                <h2>{{ vehicle.plate }}</h2>
                <h3 v-if="vehicle.model && vehicle.type">
                  {{ vehicle.model }}
                  <br />
                  {{ vehicle.type }}
                </h3>
                <h3>{{ tire.tire_storage }}</h3>
              </td>
              <td>
                <QrcodeVue
                  style="display: flex; justify-content: center"
                  class="qrcode"
                  :value="qrValue"
                  level="M"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- tire 3 -->
      <div class="html2pdf__page-break"></div>
      <div class="document" v-if="tire && customerName && vehicle">
        <h3>{{ customerName }}</h3>
        <table>
          <tbody>
            <tr>
              <td>
                <h2>{{ vehicle.plate }}</h2>
                <h3 v-if="vehicle.model && vehicle.type">
                  {{ vehicle.model }}
                  <br />
                  {{ vehicle.type }}
                </h3>
                <h3>{{ tire.tire_storage }}</h3>
              </td>
              <td>
                <QrcodeVue
                  style="display: flex; justify-content: center"
                  class="qrcode"
                  :value="qrValue"
                  level="M"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- tire 4 -->
      <div class="html2pdf__page-break"></div>
      <div class="document" v-if="tire && customerName && vehicle">
        <h3>{{ customerName }}</h3>
        <table>
          <tbody>
            <tr>
              <td>
                <h2>{{ vehicle.plate }}</h2>
                <h3 v-if="vehicle.model && vehicle.type">
                  {{ vehicle.model }}
                  <br />
                  {{ vehicle.type }}
                </h3>
                <h3>{{ tire.tire_storage }}</h3>
              </td>
              <td>
                <QrcodeVue
                  style="display: flex; justify-content: center"
                  class="qrcode"
                  :value="qrValue"
                  level="M"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "../..//../node_modules/html2pdf.js/dist/html2pdf";
import QrcodeVue from "qrcode.vue";

export default {
  name: "assessmentPDF",

  props: ["tire", "customerName", "customerID", "vehicle", "printNow"],

  data() {
    return {
      qrValue: undefined,
      qrSize: 150,
    };
  },

  components: {
    QrcodeVue,
  },

  watch: {
    printNow() {
      if (this.printNow) {
        this.prepareForPrint();
      }
    },
  },

  computed: {
    hasMeasurements() {
      if (this.tire && this.tire.width && this.tire.radius && this.tire.crossSection)
        return true;

      return false;
    },
    measurements() {
      return this.tire.width + "/" + this.tire.radius + "R" + this.tire.crossSection;
    },
  },

  methods: {
    prepareForPrint() {
      this.qrValue =
        "https://rros.reifen-reibold.de/fahrzeug/" + this.customerID + "/" + this.vehicle.id;
      let element = document.getElementById("element-to-print-label");
      element.style = "display:block";

      const opt1 = {
        filename: "Bewertung_.pdf",
        pagebreak: { before: ".pageBreak" },
        margin: 0,
        autoPrint: false,
        image: { type: "jpeg", quality: 1 },
        jsPDF: { format: [75, 50], orientation: "landscape" },
        // jsPDF: { format: "a4", orientation: "landscape" },
        html2canvas: { allowTaint: true, useCORS: true, imageTimeout: 0 },
        preview: true,
      };

      html2pdf()
        .from(element)
        .set(opt1)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          pdf.autoPrint();

          var blobURL = pdf.output("bloburl");

          var iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = blobURL;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };

          // window.open(pdf.output("bloburl"), "_blank");

          element.style.display = "none";
        });
    },
  },
};
</script>

<style scoped>
.document {
  width: 7.5cm; /** alles 1 cm größer für unseren drucker */
  height: 5cm;
  min-height: 5cm;
  padding-bottom: auto;
  /* background-color: rgb(199, 199, 199); */
  /* margin: 1cm; */
  padding: 2mm;
}
.pdf-content {
  width: 7.4cm;
  height: 4.9cm;
  min-height: 4.9cm;
}
table {
  width: 7.5cm !important;
  height: 4cm !important;
}
.qrcode canvas {
  height: 3cm !important;
  width: 3cm !important;
}
</style>
