<template>
    <div>
        <headerComponent> </headerComponent>

        <router-view> </router-view>
    </div>
</template>

<script>
import headerComponent from "../../components/Header/HeaderBar.vue"

export default{
    name: "mainPage",

    components: {
        headerComponent
    },

    data() {
        return {}
    },

    methods: {

    }
}
</script>
