<template>
  <div>
    <v-app>
      <v-container fluid fill-height height="90vh">
        <v-row align="center" justify="center">
          <v-col cols="10">
            <v-card
              style="
                min-height: 30vh;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              "
              class="py-12 px-4"
            >
            <div style="height:80px">
            <a href="https://www.reifen-reibold.de/">
              <v-img
                src="../../assets/images/ReifenReiboldLogo.png"
                contain
                :height="isMobile ? '35px' : '60px'"
              ></v-img>
            </a>
            </div>
              <h1 style="color:var(--v-primary-base)">Vielen Dank für ihre Terminanfrage!</h1>
              <h3 style="color:var(--v-primary-base)">Wir melden uns schnellstmöglich zurück!</h3>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    isMobile() {
      switch(this.$vuetify.breakpoint.name){
        case 'xs':
          return true
        case 'sm':
          return true
        case 'md':
          return true
        default:
          return false
      }
    },
    logoHeight(){
      switch(this.$vuetify.breakpoint.name){
        case 'xs':
          return '30px'
        case 'sm':
          return '35px'
        case 'md':
          return '40px'
        case 'lg':
          return '60px'
        default:
          return '80px'
      }
    }
  }
}
</script>