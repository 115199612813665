<template>
  <div>
    <v-container fill-height fluid class="innerCard mb-16">
      <v-row align="center" justify="center">
        <v-col :cols="colLimit">
          <v-card
            height="fit-content"
            min-height="80vh"
            class="ma-8 pa-8 mt-16"
            flat
          >
            <v-container fluid class="ma-0 pa-0">
              <v-row class="ma-0 pa-0">
                <v-spacer />
                <v-col>
                  <h3>Terminübersicht {{ date }}</h3>
                </v-col>
                <v-spacer />
                <v-col cols="4" class="pa-0">
                  <v-combobox
                    :value="displayStages"
                    :items="stages"
                    multiple
                    chips
                    close
                    @input="switchStageDisplay"
                    class="hideInputBottomSpace mb-0"
                    label="Bühnen"
                  >
                    <template
                      v-slot:selection="{ attrs, item, parent, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        label
                        small
                      >
                        <span class="pr-2">
                          {{ item }}
                        </span>
                        <v-icon small @click="parent.selectItem(item)">
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>

                <!-- <v-col cols="3" class="ma-0 pa-0">
                  <v-select
                    class="ma-0 pa-0"
                    :items="stages"
                    label="Bühne"
                    v-model="stage"
                    @change="displayAppointmentsByStage(true)"
                  ></v-select>
                </v-col>
                -->
              </v-row>
            </v-container>
            <v-divider />

            <v-container
              fluid
              class="ma-0 pa-0 mt-10"
              v-if="appointments.length == 0"
            >
              <v-row class="ma-0 pa-0">
                <v-spacer />
                <v-col cols="6" class="ma-0 pa-0">
                  Keine Termine vorhanden!
                </v-col>
                <v-spacer />
              </v-row>
            </v-container>

            <div v-else>
              <v-container
                v-if="displayedAppointments.length == 0"
                fluid
                class="ma-0 pa-0 mt-10"
              >
                <v-row class="ma-0 pa-0">
                  <v-spacer />
                  <v-col
                    cols="3"
                    class="ma-0 pa-0"
                    v-for="app in displayedAppointments"
                    :key="app.id"
                  >
                    Keine Termine auf dieser Bühne!
                  </v-col>
                  <v-spacer />
                </v-row>
              </v-container>

              <v-container v-else fluid class="ma-0 pa-0 mt-10">
                <v-row class="ma-0 pa-0">
                  <v-col
                    :cols="numOfCards"
                    class="ma-0 pa-0"
                    v-for="app in displayedAppointments.slice(
                      0,
                      currentTimeIndex
                    )"
                    :key="app.id"
                  >
                    <AppointmentDayCard
                      @switchStorage="switchStorage"
                      @print="printLabels"
                      opacity="0.6"
                      :appointment="app"
                      :icons="icons"
                      :tireAppType="tireAppType"
                    />
                  </v-col>
                </v-row>

                <v-row
                  style="
                    color: var(--v-primary-base);
                    font-weight: bolder;
                    font-size: x-large;
                  "
                >
                  <v-col>
                    {{ currentTime }}
                  </v-col>
                </v-row>
                <v-divider
                  style="border-width: 1px; border-color: var(--v-primary-base)"
                />

                <v-row class="ma-0 pa-0">
                  <v-col
                    :cols="numOfCards"
                    class="ma-0 pa-0"
                    v-for="app in displayedAppointments.slice(
                      currentTimeIndex,
                      displayedAppointments.length
                    )"
                    :key="app.id"
                  >
                    <AppointmentDayCard
                      @switchStorage="switchStorage"
                      @print="printLabels"
                      :opacity="1"
                      :appointment="app"
                      :icons="icons"
                      :tireAppType="tireAppType"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <LabelPDF
      :tire="tireData"
      :customerName="customerName"
      :customerID="customerID"
      :vehicle="vehicleData"
      :printNow="printLabelPDF"
      style="z-index: -1; opacity: 0"
    />
  </div>
</template>

<script>
import { getReadableDate } from "../../js/helperFunctions.js";
import AppointmentDayCard from "./AppointmentDayCard.vue";
import LabelPDF from "../PDF/LabelPDF.vue";

const axios = require("axios");

export default {
  name: "appointmentDayList",

  data() {
    return {
      date: new Date().toLocaleString([], {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),

      stages: [],
      stage: undefined,
      displayStages: [],

      appointments: [],

      displayedAppointments: [],

      currentTimeIndex: undefined,
      counterInterval: undefined,
      currentTime: undefined,

      icons: [],
      tireAppType: undefined,

      printLabelPDF: 0,
      tireData: undefined,
      customerName: undefined,
      customerID: undefined,
      vehicleData: undefined,
    };
  },

  components: {
    AppointmentDayCard,
    LabelPDF,
  },

  computed: {
    colLimit() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 10;
        case "md":
          return 8;
        case "lg":
          return 9;
        case "xl":
          return 7;
        default:
          return 8;
      }
    },
    numOfCards() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 4;
        case "xl":
          return 3;
        default:
          return 8;
      }
    },
  },

  methods: {
    switchStorage(appointment) {
      if (appointment.Vehicle.tire_set.length != 2) return;

      if (
        appointment.Vehicle.tire_set[0].in_storage !=
        appointment.Vehicle.tire_set[1].in_storage
      ) {
        if (
          !(
            !appointment.Vehicle.tire_set[0].Space ||
            !appointment.Vehicle.tire_set[1].Space
          )
        )
          return;
      }

      let dateString = new Date().toLocaleString().split(",")[0];
      dateString = dateString.split(".");

      let newStorage = appointment.Vehicle.tire_set[0].Space
        ? appointment.Vehicle.tire_set[0].Space
        : appointment.Vehicle.tire_set[1].Space;

      let tire0Data, tire1Data;

      if (appointment.Vehicle.tire_set[0].Space) {
        tire0Data = {
          id: appointment.Vehicle.tire_set[0].id,
          in_storage: !appointment.Vehicle.tire_set[0].in_storage,
          storage_date:
            dateString[2] + "-" + dateString[1] + "-" + dateString[0],
          Space: null,
        };

        tire1Data = {
          id: appointment.Vehicle.tire_set[1].id,
          in_storage: !appointment.Vehicle.tire_set[1].in_storage,
          storage_date:
            dateString[2] + "-" + dateString[1] + "-" + dateString[0],
          Space: newStorage,
        };
      } else {
        tire0Data = {
          id: appointment.Vehicle.tire_set[1].id,
          in_storage: !appointment.Vehicle.tire_set[1].in_storage,
          storage_date:
            dateString[2] + "-" + dateString[1] + "-" + dateString[0],
          Space: null,
        };

        tire1Data = {
          id: appointment.Vehicle.tire_set[0].id,
          in_storage: !appointment.Vehicle.tire_set[0].in_storage,
          storage_date:
            dateString[2] + "-" + dateString[1] + "-" + dateString[0],
          Space: newStorage,
        };
      }

      axios
        .patch(
          axios.defaults.baseURL + "tires/simple/" + tire0Data.id,
          tire0Data
        )
        .then(() => {
          axios
            .patch(
              axios.defaults.baseURL + "tires/simple/" + tire1Data.id,
              tire1Data
            )
            .then(() => {
              axios
                .patch(
                  axios.defaults.baseURL +
                    "appointments/patch/" +
                    appointment.id,
                  {
                    id: appointment.id,
                    state_tire: 1,
                  }
                )
                .then(() => {
                  // window.location.reload();
                  appointment.state_tire = 1;
                  this.$forceUpdate();
                })
                .catch((e) => console.log(e));
            });
        });
    },
    printLabels(appointment) {
      let found = false;
      for (let i = 0; i < appointment.Vehicle.tire_set.length; i++) {
        if (appointment.Vehicle.tire_set[i].in_storage) {
          this.tireData = appointment.Vehicle.tire_set[i];
          found = true;
        }
      }

      if (!found) return;

      this.printLabelPDF++;

      this.customerName =
        (appointment.Vehicle.Customer.company || "") +
        " " +
        (appointment.Vehicle.Customer.first_name || "") +
        " " +
        (appointment.Vehicle.Customer.last_name || "");
      this.customerID = appointment.Vehicle.Customer.id;
      this.vehicleData = appointment.Vehicle;
    },
    getReadableDate: getReadableDate,
    fetchEvents() {
      this.appointments = [];

      axios
        .get(
          axios.defaults.baseURL +
            "appointments/by/date/complex/?start=" +
            this.date +
            "&end=" +
            this.date +
            "&location=1"
        )
        .then((resp) => {
          for (let i = 0; i < resp.data.appointments.length; i++) {
            resp.data.appointments[i].isTemp = false;
            this.appointments.push(resp.data.appointments[i]);
          }

          for (let i = 0; i < resp.data.temp_appointments.length; i++) {
            resp.data.temp_appointments[i].isTemp = true;
            this.appointments.push(resp.data.temp_appointments[i]);
          }

          this.displayAppointmentsByStage(true);
        });

      axios
        .get(axios.defaults.baseURL + "appointmenttypes")
        .then((resp) => {
          for (let i = 0; i < resp.data.length; i++) {
            if (resp.data[i].name.includes("Reifen")) {
              this.tireAppType = resp.data[i].id;
            }
          }

          for (let i = 0; i < resp.data.length; i++) {
            for (
              let j = 0;
              j < resp.data[i].appointmentsubtype_set.length;
              j++
            ) {
              var rawFile = new XMLHttpRequest();
              rawFile.open(
                "GET",
                resp.data[i].appointmentsubtype_set[j].icon,
                false
              );
              rawFile.send(null);

              this.icons[resp.data[i].appointmentsubtype_set[j].id] =
                rawFile.responseText;
            }
          }
        })
        .catch((e) => console.log(e));
    },
    switchStageDisplay(val) {
      console.log(val);

      let settingKey = localStorage.getItem("id") + "_stages";

      localStorage.setItem(settingKey, val);

      // console.log(localStorage.getItem(settingKey));

      this.displayStages = [];

      for (let i = 0; i < val.length; i++) {
        this.displayStages.push(val[i]);
      }

      this.displayAppointmentsByStage(true);
    },
    displayAppointmentsByStage(forceUpdate = false) {
      // let settingKey = localStorage.getItem("id") + "_stages";
      // console.log(localStorage.getItem(settingKey));

      this.displayedAppointments = [];

      for (let i = 0; i < this.appointments.length; i++) {
        //   if (this.appointments[i].Stage.name == this.stage) {
        for (let j = 0; j < this.displayStages.length; j++) {
          if (this.appointments[i].Stage.name == this.displayStages[j]) {
            this.displayedAppointments.push(this.appointments[i]);
          }
        }

        // }
      }

      for (let i = 0; i < this.displayedAppointments.length - 1; i++) {
        for (let j = i + 1; j < this.displayedAppointments.length; j++) {
          if (
            this.displayedAppointments[i].time >
            this.displayedAppointments[j].time
          ) {
            let temp = this.displayedAppointments[i];
            this.displayedAppointments[i] = this.displayedAppointments[j];
            this.displayedAppointments[j] = temp;
          }
        }
      }

      this.calculateTimeDivider(forceUpdate);

      this.counterInterval = setInterval(
        function() {
          this.calculateTimeDivider();
        }.bind(this),
        30000
      );
    },
    calculateTimeDivider(forceUpdate = false) {
      let now = new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      if (this.currentTime != now || forceUpdate) {
        this.currentTime = now;

        let found = false;
        for (let i = 0; i < this.displayedAppointments.length; i++) {
          if (this.displayedAppointments[i].time < this.currentTime) {
            continue;
          } else {
            this.currentTimeIndex = i;
            found = true;
            break;
          }
        }

        if (!found) {
          this.currentTimeIndex = this.displayedAppointments.length;
        }

        this.$forceUpdate();
      }
    },
  },

  destroyed() {
    clearInterval(this.counterInterval);
  },

  mounted() {
    axios
      .get(axios.defaults.baseURL + "stages/by/location/?location=1")
      .then((resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          this.stages.push(resp.data[i].name);
        }

        this.stage = this.stages[0];

        let settingKey = localStorage.getItem("id") + "_stages";

        if (localStorage.getItem(settingKey) == undefined) {
          this.displayStages = this.stages;
        } else {
          this.displayStages = localStorage.getItem(settingKey).split(",");
        }

        // console.log(this.displayStages);

        this.fetchEvents();
      })
      .catch((e) => console.log(e));
  },
};
</script>
