<template>
  <div>
    <v-app>
      <v-container fluid fill-height height="90vh">
        <v-row align="center" justify="center">
          <v-col :cols="isMobile ? 12 : 10">
            <v-card style="min-height: 50vh" class="py-12 px-4">
              <v-container>
                <v-row>
                  <v-spacer />
                  <v-col
                    :cols="mainCols"
                    style="display: flex; justify-content: center; flex-wrap: wrap"
                  >
                  <v-container>
                  <v-row>
                  <v-col cols="12" style="display:flex; justify-content: center;">
                    <v-date-picker
                      width="100%"
                      v-model="selectedDate"
                      locale="de-DE"
                      color="primary"
                      show-current
                      first-day-of-week="1"
                      elevation="2"
                      class="pb-4"
                      :allowed-dates="allowedDates"
                      @click:date="fetchSlots"
                      :disabled="buttonsDisabled"
                    ></v-date-picker>
                  </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" style="display:flex; justify-content: center;">
                      <v-select
                        v-model="selectedTime"
                        class="mt-6"
                        label="Uhrzeit"
                        solo
                        :items="availableTimes"
                        :disabled="buttonsDisabled"
                        style="max-width: 350px"
                      >
                        <template v-slot:selection="{ item }"> {{ item }} Uhr </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  </v-container>
                  </v-col>
                  <v-spacer />
                  <v-col :cols="mainCols">
                    <h2 style="color: var(--v-primary-base)">Jetzt Termin anfragen</h2>
                    <h3 style="color: var(--v-secondary-base)">
                      Wählen Sie einen verfügbaren Tag und eine Uhrzeit aus, um einen
                      Termin anzufragen.
                    </h3>
                    <br />
                    <h2 style="color: var(--v-primary-base)" class="mt-6">
                      Ihr Wunschtermin
                    </h2>
                    <h3 style="color: var(--v-secondary-base)">
                      {{
                        selectedDate
                          ? getHumanReadableDate(selectedDate)
                          : "Datum auswählen"
                      }}
                    </h3>
                    <h3 style="color: var(--v-secondary-base)">
                      {{ selectedTime ? selectedTime + " Uhr" : "Uhrzeit wählen" }}
                    </h3>
                    <br />
                    <v-btn
                      :disabled="buttonsDisabled"
                      x-large
                      class="basicButton"
                      @click="sendAppointmentRequest"
                      ><h2>Anfrage senden</h2></v-btn
                    >
                  </v-col>
                  <v-spacer />
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="fullScreenOverlay" v-if="loading">
        <div class="overlayBackground" style="opacity: 0.1"></div>
        <v-progress-circular
          indeterminate
          color="primary"
          class="overlayDisplay"
        ></v-progress-circular>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["userData"],
  data() {
    return {
      appointment: undefined,
      availableDays: [],
      availableTimes: [],
      selectedDate: undefined, // Date in YYYY-MM-DD format
      selectedTime: undefined,
      buttonsDisabled: false,

      loading: false,
    };
  },
  computed: {
    mainCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 5;
        default:
          return 5;
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    getHumanReadableDate(date) {
      let temp = new Date(date);
      let new_date = `${temp.toLocaleString("de-DE", {
        weekday: "short",
      })}, ${temp.getDate()}. ${temp.toLocaleString("de-DE", { month: "long" })}`;

      return new_date;
    },
    allowedDates(val) {
      if (this.availableDays.indexOf(val) !== -1) {
        return true;
      }
      return false;
    },
    fetchDates() {
      axios
        .get(axios.defaults.baseURL + "appointments/slots/")
        .then((resp) => {
          this.availableDays = resp.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchSlots(day) {
      axios
        .get(axios.defaults.baseURL + "appointments/slots/by/date/?date=" + day)
        .then((resp) => {
          this.selectedTime = undefined;

          for (let i = 0; i < resp.data.length; i++) {
            let time = resp.data[i].start_time.split(":");
            if (this.availableTimes.indexOf(resp.data[i].start_time) == -1) {
              this.availableTimes.push([time[0], time[1]].join(":"));
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    sendAppointmentRequest() {
      this.loading = true;

      let dataObject = {
        date: this.selectedDate,
        time: this.selectedTime,
        email: this.userData.email,
        plate: this.userData.plate,
      };
      axios
        .post(axios.defaults.baseURL + "appointments/book/", dataObject)
        .then(() => {
          this.buttonsDisabled = true;
          this.$emit("booked");
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.fetchDates();
  },
};
</script>
