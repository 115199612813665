<template>
  <div id="vehicleDetail">
    <v-form ref="vehicleDetailForm">
      <v-container fill-height fluid height="90vh">
        <v-row align="center" justify="center">
          <v-col :cols="colLimit">
            <v-card class="ma-8 pa-8 mt-16" flat>
              <v-container fluid>
                <v-row>
                  <v-col cols="auto">
                    <v-btn color="primary" @click="backToCustomer()">
                      <v-icon class="pr-2">mdi-arrow-left</v-icon>
                      {{ name }}
                    </v-btn>
                  </v-col>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="py-0"
                    style="display: flex; align-items: center"
                  >
                    <v-btn color="error" @click="showDeletionWarning = true"
                      ><v-icon>mdi-delete</v-icon> Löschen
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="mt-10" style="align-items: center">
                  <v-col :cols="colWidth" class="py-0"><h3>Fahrzeugdaten</h3></v-col>

                  <v-spacer />
                  <v-col cols="auto" v-if="checkPerm(4)">
                    <v-btn @click="gotoAppointmentForVehicle()" class="basicButton"
                      ><v-icon>mdi-calendar</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="dynamicCol" class="py-0">
                    <v-text-field
                      label="Kennzeichen (AB-12 34)"
                      v-model="plate"
                      :rules="plateRules"
                    ></v-text-field>
                  </v-col>
                  <v-spacer v-if="dynamicCol == 2" />
                  <v-col :cols="dynamicCol" class="py-0">
                    <v-combobox
                      label="Hersteller"
                      :value="manufacturer"
                      @keydown="writeVehicleManufacturerByEvent"
                      @input="writeVehicleManufacturerByInput"
                      :items="manufacturers"
                      :rules="requiredRules"
                    ></v-combobox>
                  </v-col>
                  <v-spacer v-if="dynamicCol == 2" />
                  <v-col :cols="dynamicCol" class="py-0">
                    <v-text-field
                      label="Model"
                      v-model="model"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-spacer v-if="dynamicCol == 2" />
                  <v-col
                    :cols="dynamicCol"
                    class="py-0"
                    style="display: flex; align-items: center"
                  >
                    <v-menu
                      class="py-0"
                      ref="inspectionDateMenu"
                      v-model="inspectionDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on="on"
                          class="pa-0 pr-3"
                          style="width: 30px; height: 30px"
                        >
                          mdi-calendar
                        </v-icon>
                        <v-text-field
                          :value="getReadableDate(inspectionDate)"
                          label="Nächste TÜV/AU"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="inspectionDate"
                        :active-picker.sync="activePicker"
                        locale="de-DE"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-spacer v-if="dynamicCol == 2" />
                  <v-col :cols="dynamicCol" class="py-0">
                    <v-text-field
                      label="SchlüsselNr."
                      :rules="integerRules"
                      v-model="keyNr"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-10">
                  <v-col :cols="colWidth" class="py-0"><h3>Fahrzeugschein</h3></v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <div class="imageUploadGridHelper">
                      <v-img
                        @click="openImgInNewTab"
                        class="pb-4"
                        :src="registrationFromDB"
                        v-if="registrationFromDB && !registration"
                      >
                      </v-img>
                      <v-img class="imageUploader">
                        <image-uploader
                          :debug="0"
                          :maxWidth="2142"
                          :maxHeight="1615"
                          :quality="0.6"
                          :autoRotate="true"
                          outputFormat="verbose"
                          :preview="true"
                          capture="camera"
                          accept="image/*"
                          id="registration"
                          v-model="registration"
                        >
                        </image-uploader>
                        <label
                          for="registration"
                          class="imageUploadButton"
                          style="width: fit-content"
                        >
                          <v-icon class="pr-8" color="white">mdi-camera</v-icon>
                          <p v-if="!registrationFromDB && !registration">
                            Fahrzeugschein Hochladen
                          </p>
                          <p v-else>Fahrzeugschein Editieren</p>
                        </label>
                      </v-img>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-10">
                  <v-col :cols="colWidth" class="py-0"><h3>Reifensätze</h3></v-col>
                </v-row>

                <v-row>
                  <v-col :cols="colWidth" class="py-2" v-if="tires.length < 2">
                    <div>
                      <v-card height="250" @click="openNewTireOverlay">
                        <v-card-text
                          style="
                            display: flex;
                            color: lightgray;
                            align-items: center;
                            justify-content: center;
                            height: 240px;
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="150px"
                            height="150px"
                            fill="currentColor"
                            class="bi bi-plus"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                            />
                          </svg>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>

                  <v-col :cols="colWidth" class="py-2" v-for="t in tires" :key="t.id">
                    <div>
                      <v-hover v-slot="{ hover }">
                        <v-card
                          :elevation="hover ? 16 : 2"
                          height="250px"
                          max-height="250px"
                          style="overflow-y: hidden"
                          @click="gotoTires(t)"
                          class="pb-2"
                        >
                          <v-card-title>
                            <div class="ml-1">
                              <v-img
                                :src="require('../../assets/icons/season_allwetter.svg')"
                                v-if="t.season == 0"
                              ></v-img>

                              <v-img
                                :src="require('../../assets/icons/season_sommer.svg')"
                                v-else-if="t.season == 1"
                              ></v-img>

                              <v-img
                                :src="require('../../assets/icons/season_winter.svg')"
                                v-else-if="t.season == 2"
                              ></v-img>
                            </div>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  style="font-size: xx-large"
                                  v-if="t.replacement"
                                  color="error"
                                  >mdi-alert</v-icon
                                >
                              </template>
                              <span>Reifen kaputt</span>
                            </v-tooltip>

                            <v-spacer />

                            {{ t.dot }}

                            <v-spacer />
                            <div>
                              <div v-if="t.type == 0">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-img
                                      v-bind="attrs"
                                      v-on="on"
                                      :src="require('../../assets/icons/type_alu.svg')"
                                    ></v-img>
                                  </template>
                                  <span>Alufelge</span>
                                </v-tooltip>
                              </div>

                              <div v-if="t.type == 1">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-img
                                      v-bind="attrs"
                                      v-on="on"
                                      :src="require('../../assets/icons/type_stahl.svg')"
                                    ></v-img>
                                  </template>
                                  <span>Stahlfelge</span>
                                </v-tooltip>
                              </div>

                              <div v-if="t.type == 2">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-img
                                      v-bind="attrs"
                                      v-on="on"
                                      :src="require('../../assets/icons/type_keine.svg')"
                                    ></v-img>
                                  </template>
                                  <span>Keine Felge</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </v-card-title>
                          <v-divider />
                          <v-card-text class="pa-0" style="height: calc(100% - 64px)">
                            <v-container fluid fill-height>
                              <v-row>
                                <v-col cols="12" class="py-0">
                                  {{ (t.manuf || "--") + " " + (t.model || "--") }} <br />
                                  <b v-if="t.value">{{ t.value + "€" }} </b>
                                  <b v-else> Kein Preis eingetragen! </b>
                                  <div>
                                    <b v-if="t.in_storage">
                                      Einlagerungsdatum:
                                      {{ getReadableDate(t.storage_date) }}</b
                                    >
                                    <b v-else-if="t.storage_date">
                                      Auslagerungsdatum:
                                      {{ getReadableDate(t.storage_date) }}</b
                                    >
                                    <b v-else>War nie eingelagert</b>
                                  </div>
                                  <b v-if="t.tire_storage">{{ t.tire_storage }} </b>
                                  <b v-else style="color: white">--</b>
                                  <p
                                    v-if="t.annotation"
                                    style="
                                      white-space: nowrap;
                                      text-overflow: ellipsis;
                                      overflow-x: hidden;
                                    "
                                  >
                                    {{ t.annotation }}
                                  </p>
                                  <p v-else style="color: white">--</p>
                                </v-col>
                              </v-row>
                              <v-row
                                class="py-0 px-2 ma-0"
                                style="height: fit-content; width: 100%"
                              >
                                <v-spacer />
                                <v-col
                                  cols="3"
                                  class="pa-2 ma-0"
                                  v-if="allowSwitchStorage"
                                >
                                  <v-btn
                                    style="min-width: unset; padding: 6px"
                                    class="my-2"
                                    color="primary"
                                    v-if="allowSwitchStorage"
                                    @click="switchStorage($event, t)"
                                  >
                                    <v-img
                                      :src="
                                        require('../../assets/icons/switchStorage.svg')
                                      "
                                    ></v-img>
                                  </v-btn>
                                </v-col>
                                <v-col cols="3" class="pa-2 ma-0">
                                  <v-btn
                                    v-if="t.in_storage"
                                    style="min-width: unset; padding: 6px"
                                    class="my-2"
                                    color="primary"
                                    @click="removeFromStorage($event, t)"
                                  >
                                    <v-img src="../../assets/icons/auslagern.svg"></v-img>
                                  </v-btn>
                                  <v-btn
                                    v-else
                                    style="min-width: unset; padding: 6px"
                                    class="my-2"
                                    color="primary"
                                    @click="addToStorage($event, t)"
                                  >
                                    <v-img src="../../assets/icons/einlagern.svg"></v-img>
                                  </v-btn>
                                </v-col>
                                <v-col cols="3" class="pa-2 ma-0" v-if="t.storage_date">
                                  <v-btn
                                    style="min-width: unset; padding: 6px"
                                    class="my-2"
                                    color="primary"
                                    @click="generateStoragePDF($event, t)"
                                  >
                                    <v-img
                                      :src="require('../../assets/icons/storagePDF.svg')"
                                    ></v-img>
                                  </v-btn>
                                </v-col>
                                <v-col cols="3" class="pa-2 ma-0" v-if="t.in_storage">
                                  <v-btn
                                    v-if="t.in_storage"
                                    style="min-width: unset; padding: 6px"
                                    class="my-2"
                                    color="primary"
                                    @click="generateLabelPDF($event, t)"
                                  >
                                    <v-img
                                      :src="require('../../assets/icons/labelPDF.svg')"
                                    ></v-img>
                                  </v-btn>
                                </v-col>
                                <v-spacer />
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <br />
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-overlay :value="showNewTire" :dark="false">
        <NewTireComponent
          @close="closeNewTires"
          :data="tireData"
          @deleted="tireDeleted"
        />
      </v-overlay>

      <div class="fullScreenOverlay" v-if="showRemoveTire">
        <div class="overlayBackground"></div>
        <WarnBanner
          class="overlayDisplay"
          @cancel="showRemoveTire = false"
          @approve="finalRemoveTireFromDB()"
          :text="'diesen Reifen'"
          :action="'final auslagern'"
          :buttonTextApprove="'Ja, auslagern'"
          :hideWarning="true"
        />
      </div>

      <div class="fullScreenOverlay" v-if="showDeletionWarning">
        <div class="overlayBackground"></div>
        <WarnBanner
          class="overlayDisplay"
          @cancel="showDeletionWarning = false"
          @approve="deleteFromDB()"
          :action="'löschen'"
          :text="'dieses Fahrzeug'"
        />
      </div>

      <StoragePDF
        :tire="tireData"
        :customerName="name"
        :customerID="customerID"
        :vehicle="vehicleData"
        :printNow="printTirePDF"
        style="z-index: -1"
      />
      <LabelPDF
        :tire="tireData"
        :customerName="name"
        :customerID="customerID"
        :vehicle="vehicleData"
        :printNow="printLabelPDF"
        style="z-index: -1; opacity: 0"
      />
    </v-form>
  </div>
</template>

<style scoped>
.document {
  width: 8.3cm;
  height: 5cm;
  min-height: 5cm;
  padding-bottom: auto;
  background-color: rgb(199, 199, 199);
}
.pdf-content {
  width: 8.3cm;
  height: 5cm;
  min-height: 5cm;
}
table {
  width: 8cm !important;
}
.qrcode canvas {
  height: 3cm !important;
  width: 3cm !important;
}
</style>

<script>
import { getReadableDate } from "../../js/helperFunctions.js";
import ImageUploader from "vue-image-upload-resize";

import NewTireComponent from "./NewTire.vue";
import StoragePDF from "../../views/PDF/StoragePDF.vue";
import LabelPDF from "../../views/PDF/LabelPDF.vue";
import WarnBanner from "../Misc/WarnBanner.vue";

const vehicleManufacturers = require("./VehicleData.json");

const axios = require("axios");

export default {
  name: "editVehicle",

  props: [],

  data() {
    return {
      name: undefined,
      customerID: undefined,
      plate: undefined,

      manufacturer: undefined,
      manufacturers: vehicleManufacturers.manufacturers,

      model: undefined,

      id: undefined,
      registration: undefined,
      inspectionDate: "",
      keyNr: undefined,

      inspectionDateMenu: false,
      activePicker: "YEAR",

      tires: [],
      tireData: undefined,

      state: "main",

      requiredRules: [(v) => !!v || "Pflichtfeld!"],
      integerRules: [(v) => !v || !isNaN(parseInt(v)) || "Gültige Zahl eingeben!"],
      plateRules: [(v) => this.checkValidPlate(v) || "Falsches Format"],

      registrationFromDB: undefined,

      showNewTire: false,
      showRemoveTire: false,

      removeableTire: undefined,

      printTirePDF: 0,
      printLabelPDF: 0,

      vehicleData: undefined,

      showDeletionWarning: false,
    };
  },

  components: {
    ImageUploader,
    NewTireComponent,
    StoragePDF,
    LabelPDF,
    WarnBanner,
  },

  watch: {
    inspectionDateMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  computed: {
    colLimit() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 10;
        case "md":
          return 8;
        case "lg":
          return 9;
        case "xl":
          return 6;
        default:
          return 8;
      }
    },
    dynamicCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 6;
        case "sm":
          return 4;
        case "md":
          return 3;
        case "lg":
          return 2;
        case "xl":
          return 2;
        default:
          return 8;
      }
    },
    colWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        case "lg":
          return 4;
        case "xl":
          return 4;
        default:
          return 4;
      }
    },
    allowSwitchStorage() {
      if (this.tires.length != 2) return false;

      if (this.tires[0].in_storage != this.tires[1].in_storage) {
        if (!this.tires[0].Space || !this.tires[1].Space) return true;
      }

      return false;
    },
  },

  methods: {
    deleteFromDB() {
      for (let i = 0; i < this.tires.length; i++) {
        if (this.tires[i].in_storage) {
          window.alert("Es sind noch eingelagerte Reifen für dieses Auto vorhanden!");
          this.showDeletionWarning = false;
          return;
        }
      }

      axios
        .patch(axios.defaults.baseURL + "vehicles/" + this.id, {
          id: this.id,
          deleted: true,
        })
        .then((resp) => {
          console.log("resp", resp);
          this.showDeletionWarning = false;

          this.backToCustomer();
        })
        .catch((e) => console.log(e));
    },
    tireDeleted() {
      this.showNewTire = false;
      this.tireData = undefined;

      this.getVehicleData();
    },
    checkPerm(val) {
      if (sessionStorage.getItem("permission") <= 2) return true;

      return sessionStorage.getItem("permission") == val;
    },
    async openImgInNewTab() {
      window.open(this.registrationFromDB, "_blank");
    },
    generateStoragePDF(event, tire) {
      this.tireData = tire;
      this.printTirePDF++;
      this.$forceUpdate();
      event.stopPropagation();
    },
    generateLabelPDF(event, tire) {
      this.tireData = tire;
      this.printLabelPDF++;
      this.showLabelPrint = true;
      this.$forceUpdate();
      event.stopPropagation();
    },
    switchStorage(event) {
      if (!this.allowSwitchStorage) return;

      let dateString = new Date().toLocaleString().split(",")[0];
      dateString = dateString.split(".");

      let newStorage = this.tires[0].Space ? this.tires[0].Space : this.tires[1].Space;

      let tire0Data, tire1Data;

      if (this.tires[0].Space) {
        tire0Data = {
          id: this.tires[0].id,
          in_storage: !this.tires[0].in_storage,
          storage_date: dateString[2] + "-" + dateString[1] + "-" + dateString[0],
          Space: null,
        };

        tire1Data = {
          id: this.tires[1].id,
          in_storage: !this.tires[1].in_storage,
          storage_date: dateString[2] + "-" + dateString[1] + "-" + dateString[0],
          Space: newStorage,
        };
      } else {
        tire0Data = {
          id: this.tires[1].id,
          in_storage: !this.tires[1].in_storage,
          storage_date: dateString[2] + "-" + dateString[1] + "-" + dateString[0],
          Space: null,
        };

        tire1Data = {
          id: this.tires[0].id,
          in_storage: !this.tires[0].in_storage,
          storage_date: dateString[2] + "-" + dateString[1] + "-" + dateString[0],
          Space: newStorage,
        };
      }

      axios
        .patch(axios.defaults.baseURL + "tires/simple/" + tire0Data.id, tire0Data)
        .then(() => {
          axios
            .patch(axios.defaults.baseURL + "tires/simple/" + tire1Data.id, tire1Data)
            .then(() => {
              this.getVehicleData();
            });
        });

      event.stopPropagation();
    },
    removeFromStorage(event, tire) {
      this.removeableTire = tire;
      this.showRemoveTire = true;

      event.stopPropagation();
    },
    finalRemoveTireFromDB() {
      if (this.removeableTire) {
        let dateString = new Date().toLocaleString().split(",")[0];
        dateString = dateString.split(".");

        let tireData = {
          id: this.removeableTire.id,
          in_storage: false,
          storage_date: dateString[2] + "-" + dateString[1] + "-" + dateString[0],
          Space: null,
        };

        axios
          .patch(
            axios.defaults.baseURL + "tires/simple/" + this.removeableTire.id,
            tireData
          )
          .then(() => {
            this.getVehicleData();
            this.showRemoveTire = false;
          });
      }

      this.removeableTire = undefined;
    },
    addToStorage(event, tire) {
      this.$router.push("/lager/" + this.customerID + "/" + this.id + "/" + tire.id);
      event.stopPropagation();
    },
    openNewTireOverlay() {
      if (this.tires.length < 2) {
        this.showNewTire = true;
      } else {
        window.alert("Maximal 2 Reifensätze!");
      }
    },
    closeNewTires(val) {
      if (val) {
        let found = false;
        for (let i = 0; i < this.tires.length; i++) {
          if (this.tires[i].id == val.id) {
            if (this.tires[i].replacement != val.replacement && val.replacement) {
              this.$router.go(this.$router.currentRoute);
            }
            // this.tires[i] = val;

            this.tires[i].type = val.type;
            this.tires[i].dot = val.dot;
            this.tires[i].season = val.season;
            this.tires[i].width = val.width;
            this.tires[i].radius = val.radius;
            this.tires[i].cross_section = val.cross_section;
            this.tires[i].weight_index = val.weight_index;
            this.tires[i].speed_index = val.speed_index;
            this.tires[i].profile_f = val.profile_f;
            this.tires[i].profile_r = val.profile_r;
            this.tires[i].value = val.value;
            this.tires[i].manufacturer = val.manufacturer;
            this.tires[i].model = val.model;
            this.tires[i].annotation = val.annotation;
            this.tires[i].replacement = val.replacement;

            found = true;
            break;
          }
        }

        if (!found) {
          this.tires.push(val);
        }

        this.$forceUpdate();
      }

      this.showNewTire = false;
      this.tireData = undefined;
    },
    gotoAppointmentForVehicle() {
      this.$store.state.appointmentVehicleID = {
        vehicleID: this.id,
        timestamp: Date.now(),
      };
      this.$router.push("/termine");
    },
    checkValidPlate() {
      if (!this.plate) return false;

      let plateArray = this.plate.split(/-| /);

      if (plateArray.length != 3) {
        return false;
      }

      return true;
    },
    checkAndCorrectPlate() {
      let plateArray = this.plate.split(/-| /);

      if (plateArray.length < 3) {
        return;
      }

      this.plate = plateArray[0] + "-" + plateArray[1] + " " + plateArray[2];
    },
    writeVehicleManufacturerByEvent(event) {
      this.manufacturer = event.target.value;
    },
    writeVehicleManufacturerByInput(val) {
      this.manufacturer = val;
    },
    gotoTires(data) {
      this.tireData = data;
      this.showNewTire = true;
    },
    getReadableDate: getReadableDate,
    closeOverlay(data) {
      this.$emit("hide", data);
    },
    sendVehicleData() {
      if (this.$refs.vehicleDetailForm.validate()) {
        this.checkAndCorrectPlate();

        let formData = new FormData();
        formData.append("id", this.id);
        formData.append("plate", this.plate);
        formData.append("key_nr", this.keyNr);
        formData.append("type", this.model);
        formData.append("model", this.manufacturer);
        formData.append(
          "inspection_date",
          this.inspectionDate ? this.inspectionDate : ""
        );
        formData.append("Customer", this.customerID);

        if (this.registration) {
          var dataURL = this.registration.dataUrl;
          dataURL = dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
          var blob = this.base64toBlob(dataURL, "image/jpeg");

          formData.append("registration", blob, "registration.jpg");
        }

        axios
          .patch(axios.defaults.baseURL + "vehicles/" + this.id, formData)
          .then(() => {})
          .catch((e) => console.log(e));
      }
    },
    base64toBlob(base64Data, contentType) {
      contentType = contentType || "";
      var sliceSize = 1024;
      var byteCharacters = atob(base64Data);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    saveBirthday(date) {
      this.$refs.birthdayMenu.save(date);
    },
    backToCustomer() {
      this.$router.push("/kunden/" + this.$route.params.customer);
    },
    getVehicleData() {
      this.tires = [];
      axios
        .get(axios.defaults.baseURL + "vehicles/complete/" + this.$route.params.vehicle)
        .then((resp) => {
          this.vehicleData = resp.data;

          this.customerID = resp.data.Customer;

          this.plate = resp.data.plate;
          this.model = resp.data.type;
          this.manufacturer = resp.data.model;
          this.keyNr = resp.data.key_nr == "undefined" ? "" : resp.data.key_nr;
          if (
            this.keyNr == undefined ||
            this.keyNr == "undefined" ||
            this.keyNr == "" ||
            this.keyNr == null ||
            this.keyNr == "null"
          ) {
            this.keyNr = 0;
          }
          this.inspectionDate = resp.data;
          this.registrationFromDB = resp.data.registration;
          this.inspectionDate = resp.data.inspection_date;
          this.id = resp.data.id;

          for (let i = 0; i < resp.data.tire_set.length; i++) {
            this.tires.push({
              type: resp.data.tire_set[i].type != null ? resp.data.tire_set[i].type : 2,
              dot: resp.data.tire_set[i].dot,
              season: resp.data.tire_set[i].season,
              width: resp.data.tire_set[i].width,
              radius: resp.data.tire_set[i].radius,
              cross_section: resp.data.tire_set[i].cross_section,
              weight_index: resp.data.tire_set[i].weight_index,
              speed_index: resp.data.tire_set[i].speed_index,
              profile_f: resp.data.tire_set[i].profile_f,
              profile_r: resp.data.tire_set[i].profile_r,
              value: resp.data.tire_set[i].value,
              manuf: resp.data.tire_set[i].manufacturer,
              model: resp.data.tire_set[i].model,
              annotation: resp.data.tire_set[i].annotation,
              id: resp.data.tire_set[i].id,
              key_nr: resp.data.tire_set[i].key_nr,
              replacement: resp.data.tire_set[i].replacement,
              storage_date: resp.data.tire_set[i].storage_date,
              in_storage: resp.data.tire_set[i].in_storage,
              tire_storage: resp.data.tire_set[i].tire_storage,
              Space: resp.data.tire_set[i].Space,
            });
          }
          axios
            .get(axios.defaults.baseURL + "customers/simple/" + resp.data.Customer)
            .then((r) => {
              this.name =
                (r.data.company || "") +
                " " +
                (r.data.first_name || "") +
                " " +
                (r.data.last_name || "");
            });
        });
    },
  },

  mounted() {
    this.getVehicleData();
  },

  updated() {
    this.sendVehicleData();
  },
};
</script>
